import React, { useState, useEffect, useContext, lazy } from 'react';
import { useLocation } from "react-router-dom";

import { Context } from './Context/AuthContext';
import Header from './components/Header';
import Footer from './components/Footer';
import NavHeader from './components/NavHeader';
import Chatbox from './components/Chatbox';
import Menu from './components/Menu';

export default function Layout({ children }) {
    const [isLogin, setIsLogin] = useState(true);
    const location = useLocation()
    const { loading, authenticated } = useContext(Context);


     //#region Effects
     useEffect(() => {
        setIsLogin(location.pathname.toLocaleLowerCase().indexOf("login") > -1 || location.pathname === "/");
    }, [location.pathname]);

    //#endregion Effects

    return (
        <React.Fragment>
            {
                !isLogin && authenticated ? (
                    <div id="main-wrapper" className="_show">
                       <NavHeader />
                       <Chatbox />
                       <Header />
                       <Menu />
                       {children}
                       <Footer />
                    </div>) : (<div style={{backgroundColor:'#F2F2F2'}}>{children}</div>) 
            }
          
        </React.Fragment>
    )
}