/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { Context } from '../../Context/AuthContext';
import { InvalidatorContext } from '../../Context/InvalidatorContext';
import { Request } from '../../scripts/Request';
import { HubObjects } from '../../scripts/HubObjects';
import { WaitObject } from '../../scripts/Wait';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment/moment';
import './index.css'
import { prettyDOM } from '@testing-library/react';


export default function Header() {
	const component_name = "header";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
	const { Invalidator, Invalidate, InvalidateStream } = useContext(InvalidatorContext);
	const [notifications, setNotifications] = useState({total: 0, notifications:[]});
	const [list, setList] = useState([]);
	const [title, setTitle] = useState("Dashboard");
	const [rand, setRand] = useState("");
	const [info, setInfo] = useState({
		nome: "",
		iam: ""
	});

	const timerIdleRef = useRef(new Date());


	//#region Effects
	useEffect(() => {
        WaitObject(user.nivel).then(() => {
            Init();
			RegisterHub();
        });

        return () => {
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);
	//#endregion Effects


	//#region Init
	const Init = () => {
		let _nome = "";
		let _arr_name = user.nome.split(' ');
		if(_arr_name.length > 1) {
			_nome = _arr_name[0] + " " + _arr_name[1];
		} else {
			_nome = _arr_name[0];
		}

		let _iam = "Não definido";

		if(user.origin < 3) {
			switch(user.iam) { 
				case 1:
					_iam = "Empresa"
					break;
				case 2:
					_iam = "Startup"
					break;
				case 3:
					_iam = "Instituição"
					break;
				case 4:
					_iam = "Empreendedor"
					break;
				case 9:
					_iam = "Não definido"
					break;
			}
		} else if(user.origin === 3) {
            _iam = "Profissional"
        } else if(user.origin === 4) {
            _iam = "Investidor"
        }

		setInfo(prev => ({
			...prev,
			nome: _nome,
			iam: _iam
		}));

		LoadNotifications();
	}
	//#endregion Init


	//#region HubObjects
	const RegisterHub = () => {
		HubObjects.Set("HEADER_TITLE", (async title => {
            setTitle(title);
        }), component_name);

		HubObjects.Set("HEADER_IAM", (async value => {
            setInfo(value);
        }), component_name);

		HubObjects.Set("HEADER_NOTIFICATIONS_CLEAR", (() => {
            HandleNotificationClick();
        }), component_name);
	};
	//#endregion HubObjects



	//#region Loaders
	const LoadNotifications = () => {
        Promise.resolve(new Request().Run("api/Notifications/Load", "GET", "", user.token, 0*24*60*1000))
        .then((data) => {
            if(data.notifications) {
                setNotifications({total: data.total, notifications: data.notifications});
            }
        }).catch(() => {
            
        });
    }


	const Readed = () => {
        Promise.resolve(new Request().Run("api/Notifications/Readed", "GET", "", user.token))
        .then((data) => {
            
        }).catch(() => {
            
        });
    }
	//#endregion Loaders




	//#region Handlers
	const HandlerImageError = (e) => {
        e.target.src = "images/db/0.jpg";
	}


	const HandleNotificationClick = () => {
		let _notifications = {...notifications};
		if(_notifications.total > 0) {
			Readed();
			setNotifications(prev => prev = {total:0, notifications: prev.notifications});
		}
	}
	//#endregion Handlers


    return (
        <div className="header">
            <div className="header-content">
                <nav className="navbar navbar-expand">
                    <div className="collapse navbar-collapse justify-content-between">
                        <div className="header-left">
                            <div className="dashboard_bar">
                                {title}
                            </div>
                        </div>

                        <ul className="navbar-nav header-right">

							<li className="nav-item dropdown notification_dropdown">
                                <a className="nav-link  ai-icon" href="#" role="button" data-toggle="dropdown" onClick={HandleNotificationClick}>
                                    <svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M9.45251 25.6682C10.0606 27.0357 11.4091 28 13.0006 28C14.5922 28 15.9407 27.0357 16.5488 25.6682C15.4266 25.7231 14.2596 25.76 13.0006 25.76C11.7418 25.76 10.5748 25.7231 9.45251 25.6682Z" fill="#3E4954"/>
										<path d="M25.3531 19.74C23.8769 17.8785 21.3995 14.2195 21.3995 10.64C21.3995 7.09073 19.1192 3.89758 15.7995 2.72382C15.7592 1.21406 14.5183 0 13.0006 0C11.4819 0 10.2421 1.21406 10.2017 2.72382C6.88095 3.89758 4.60064 7.09073 4.60064 10.64C4.60064 14.2207 2.12434 17.8785 0.647062 19.74C0.154273 20.3616 0.00191325 21.1825 0.240515 21.9363C0.473484 22.6721 1.05361 23.2422 1.79282 23.4595C3.08755 23.8415 5.20991 24.2715 8.44676 24.491C9.84785 24.5851 11.3543 24.64 13.0007 24.64C14.646 24.64 16.1524 24.5851 17.5535 24.491C20.7914 24.2715 22.9127 23.8415 24.2085 23.4595C24.9477 23.2422 25.5268 22.6722 25.7597 21.9363C25.9983 21.1825 25.8448 20.3616 25.3531 19.74Z" fill="#3E4954"/>
									</svg>
									{ notifications && notifications.notifications.length > 0 && (
									<span className={'badge light text-white bg-primary rounded-circle' + (notifications.total===0?' hide':'')}>{notifications.total}</span>
									)}
                                </a>


                                <div className="dropdown-menu dropdown-menu-right">
                                    <div id="DZ_W_Notification1" className="widget-media dz-scroll p-3 height380">
										<ul className="timeline">
											{
												notifications && notifications.notifications && notifications.notifications.map((item, i) => (
													<li key={'notif'+i}>
														<div className="timeline-panel">
															<div className="media mr-2">
																{
																	item.icon === '' && item.letters !== '' && (
																		<>
																			{item.letters}
																		</>
																	)
																}

																{
																	item.icon !== '' && item.letters === '' && (
																		<i className={item.icon}></i>
																	)
																}

																{
																	item.img !== '' && item.letters === '' && item.icon === '' && (
																		<img alt="image" width="50" src={item.img} />
																	)
																}

															</div>
															<div className="media-body">
																<h6 className="mb-1">{item.content}</h6>
																<small className="d-block">{moment(item.data).format('DD/MM/YYYY HH:mm')}</small>
															</div>
														</div>
													</li>
												))
											}

										</ul>
									</div>
									<LinkContainer to={'/Notificacoes'}>
                                    	<a className="all-notification">Ver todas notificações <i className="ti-arrow-right"></i></a>
									</LinkContainer>
                                </div>
                            </li>
							

                            <li className="nav-item dropdown header-profile">
                                <a className="nav-link" href="#" role="button" data-toggle="dropdown">
                                    <img src={'/images/db/' + user.user_id + '.jpg?' +  (Invalidator['profile_image'])} width="20" alt="" onError={HandlerImageError}/>
									<div className="header-info">
										<span className="text-black">{info.nome}</span>
										<p className="fs-12 mb-0">{info.iam}</p>
									</div>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
									<LinkContainer to={'/Cadastro'}>
										<a className="dropdown-item ai-icon">
											<svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" className="text-primary" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
											<span className="ml-2">Meu Cadastro </span>
										</a>
									</LinkContainer>

									<LinkContainer to={'/Login'}>
                                    <a href="page-login.html" className="dropdown-item ai-icon">
                                        <svg id="icon-logout" xmlns="http://www.w3.org/2000/svg" className="text-danger" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                                        <span className="ml-2">Sair </span>
                                    </a>
									</LinkContainer>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    )
}