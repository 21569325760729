export const UnCurrency = (value) => {
    let so = 0;
    try {
        let o = value.toString();
        if (o != null && o != undefined) {
            if (o.indexOf("$") > -1) {
                o = o.replace("R$", "");
                o = o.replace(" ", "");
                o = o.replace(",", "");
                o = o.replace(".", "");
                so = eval(o / 100);
            } else {
                so = eval(o);
            }
        }
    } catch (e) { }

    return so;
}


export const Currency = (value) => {
    let so = 0;
    if (typeof(value) == "string") {
        let o = value.toString();
        if (o !== null && o !== undefined) {
            if (o.indexOf("$") > -1) {
                o = o.replace("R$", "");
                o = o.replace(" ", "");
                o = o.replace(",", "");
                o = o.replace(".", "");
                so = parseFloat(o / 100);
            } else {
                so = parseFloat(o);
            }
        }
    } else {
        so = value;
    }

    if(isNaN(so)) so = 0;

    //console.log(so, new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(so).toString());

    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(so).toString();
}


export const Number = (value, maxlength, decimals) => {
    let so = 0;
    
    let _decimals = 2;
    if(decimals !== undefined && !isNaN(decimals) && decimals >= 0 ) _decimals = decimals;

    if(!value) value = 0;

    let o;
    try {
        o = eval(value).toFixed(_decimals).toString();
    } catch(e) {
        o = value.toString();
    }

    let isNegative = o.indexOf("-") > -1;
    if(!isNegative && maxlength && maxlength > 0) {
        o = o.substr(0, maxlength-1);
    }


   

    if (o !== null && o !== undefined) {
        o = o.replace(/[^0-9]/g, '');
        so = parseFloat(o/(Math.pow(10,_decimals)));
    }
    if (isNaN(so)) so = 0;
    
    //console.log(value, o, decimals)


    let r = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: _decimals, maximumFractionDigits: _decimals }).format(so);
    r = (isNegative?'-':'') + r.replace(/[^0-9.,]/g, '');


    return r;
}


export const Uuid = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}



export const RemoveAcentos = (str) => {
    if (str) {
        let com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
        let sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
        let novastr = "";
        for (let i = 0; i < str.length; i++) {
            let troca = false;
            for (let a = 0; a < com_acento.length; a++) {
                if (str.substr(i, 1) == com_acento.substr(a, 1)) {
                    novastr += sem_acento.substr(a, 1);
                    troca = true;
                    break;
                }
            }
            if (troca == false) {
                novastr += str.substr(i, 1);
            }
        }
        return novastr;
    } else {
        return "";
    }
}


export const IsCPF = (cpf) => {
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf === '') return false;
    // Elimina CPFs invalidos conhecidos	
    if (cpf.length != 11 ||
        cpf === "00000000000" ||
        cpf === "11111111111" ||
        cpf === "22222222222" ||
        cpf === "33333333333" ||
        cpf === "44444444444" ||
        cpf === "55555555555" ||
        cpf === "66666666666" ||
        cpf === "77777777777" ||
        cpf === "88888888888" ||
        cpf === "99999999999")
        return false;
    // Valida 1o digito	
    let add = 0;
    for (let i = 0; i < 9; i++)
        add += parseInt(cpf.charAt(i)) * (10 - i);
    let rev = 11 - (add % 11);
    if (rev === 10 || rev === 11)
        rev = 0;
    if (rev !== parseInt(cpf.charAt(9)))
        return false;
    // Valida 2o digito	
    add = 0;
    for (let i = 0; i < 10; i++)
        add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11)
        rev = 0;
    if (rev !== parseInt(cpf.charAt(10)))
        return false;
    return true;
}


export const IsCNPJ = (cnpj) => {
    
}


export const Base64ToFile = (b64Data, filename, contentType) => {
    let sliceSize = 512;
    let byteCharacters = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice = byteCharacters.slice(offset, offset + sliceSize);
        let byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    var file = new File(byteArrays, filename, {type: contentType});
    return file;
}


export const CaseMode = (t) => {
    let r = "";

    if (t !== "" && t !== null && t !== undefined) {
        var e = t.split(' ');
        for (var i = 0; i < e.length; i++) {
            if (e[i].length > 0) {
                if (e[i].toLowerCase() !== "de" && e[i].toLowerCase() !== "da" && e[i].toLowerCase() !== "do" && e[i].toLowerCase() !== "dos" && e[i].toLowerCase() !== "das" && e[i].toLowerCase() !== "e") {
                    var p1 = e[i].substring(0, 1);
                    var p2 = e[i].substring(1);

                    e[i] = p1.toUpperCase() + p2.toLowerCase();
                }
                else {
                    e[i] = e[i].toLowerCase();
                }
            }
        }

        r = e.join(" ");
    }

    return r;
}