/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { InvalidatorContext } from '../../../Context/InvalidatorContext';
import { Context } from '../../../Context/AuthContext'
import { Sleep, WaitObject } from '../../../scripts/Wait';
import { Request } from '../../../scripts/Request';
import { Currency } from '../../../scripts/StringUtils';
import { HubObjects } from '../../../scripts/HubObjects';
import { FormGenerator } from '../../Form/FormGenerator';
import { useParams } from 'react-router-dom';
import ReactGA from 'react-ga';
import './index.css';


export default function SmartValuation() {
    const component_name = "admin/smartvaluation";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Invalidator, Invalidate, InvalidateStream } = useContext(InvalidatorContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [share, setShare] = useState(false);
    const _formUserRef = useRef();
    const _formLoadedRef = useRef();
    const warmupId = useRef("");
    const loadPrestineRef = useRef(false);
    const timerIdleRef = useRef(new Date());

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const [uinfo, setUinfo] = useState({
		nome: "",
		iam: ""
	});


    const [info, setInfo] = useState({
        points_max: 0,
        points: 0,
        percent: 0,
        risco: 0,
        valuation: 0,
        projecao_anual: 0,
        custo_anual: 0,
        investimento: 0,
        projecao_clientes: 0,
        open: 0
    });



    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        WaitObject(user.nome).then(() => {
            Init();
        });

        return () => {
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(loading || pageLoading){
         window.$('#preloader').fadeIn(500);
        } else {
         window.$('#preloader').fadeOut(500);
        }
     }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = () => {
        if(!loadPrestineRef.current) {
            loadPrestineRef.current = true;
        
            HubObjects.Exec("HEADER_TITLE", "Smart Valuation");

            let _nome = "";
            let _arr_name = user.nome.split(' ');
            if(_arr_name.length > 1) {
                _nome = _arr_name[0] + " " + _arr_name[1];
            } else {
                _nome = _arr_name[0];
            }

            let _iam = "Não definido";

            if(user.origin < 3) {
                switch(user.iam) { 
                    case 1:
                        _iam = "Empresa"
                        break;
                    case 2:
                        _iam = "Startup"
                        break;
                    case 3:
                        _iam = "Instituição"
                        break;
                    case 4:
                        _iam = "Empreendedor"
                        break;
                    case 9:
                        _iam = "Não definido"
                        break;
                }
            } else if(user.origin === 3) {
                _iam = "Profissional"
            } else if(user.origin === 4) {
                _iam = "Investidor"
            }


            setUinfo(prev => ({
                ...prev,
                nome: _nome,
                iam: _iam
            }));

            setTimeout(() => {
                window.dzChartlist.load();
            }, 100);
        }
    }
    //#endregion Init


    //#region Loaders
    const LoadCategorias = () => {
        Promise.resolve(new Request().Run("api/Auth/Categorias", "GET", "", "", 1*24*60*1000))
        .then((data) => {
            let v = _formUserRef.current.GetDataValue("categoria");
            _formUserRef.current.Select("categoria", data);
            //_formUserRef.current.Value("categoria", (v && v !== undefined && v !== null ? v : data[0].id));
            LoadSubcategorias((v && v !== undefined && v !== null ? v : data[0].id));
        }).catch(() => {
            
        });
    }


    const LoadSubcategorias = (id) => {
        Promise.resolve(new Request().Run("api/Auth/Subcategorias?cat="+id, "GET", "", "", 1*24*60*1000))
        .then((data) => {
            //let v = _formUserRef.current.GetDataValue("subcategoria");
            if(data.length > 0) {
                _formUserRef.current.Select("subcategoria", data, "");
                //_formUserRef.current.Value("subcategoria", (v && v !== undefined && v !== null ? v : data[0].id));
            } else {
                _formUserRef.current.Select("subcategoria", [{id: 0, name: "Geral"}], "");
                //_formUserRef.current.Value("subcategoria", (v && v !== undefined && v !== null ? v : data[0].id));
            }
        }).catch(() => {
           
        });
    }


    const LoadSmartValuation = () => {
        if(warmupId.current !== "" && warmupId.current !== undefined) {
            Promise.resolve(new Request().Run("api/Warmup/SmartValuation?id="+warmupId.current, "GET", "", user.token, 0*1000))
            .then((data) => {
                setInfo({
                    points: data.points,
                    points_max: data.points_max,
                    percent: data.percent,
                    risco: data.risco,
                    valuation: data.valuation,
                    projecao_anual: data.projecao_anual,
                    custo_anual: data.custo_anual,
                    investimento: data.investimento,
                    projecao_clientes: data.projecao_clientes,
                    margem: data.margem,
                    open: data.abertura
                });

                setTimeout(() => { window.dzChartlist.load();}, 100);
            }).catch(() => {
            
            });
        }
    }
    //#endregion Loaders


    //#region Form Handlers
    const click_add_diferencial = async() => {
        //await _formUserRef.current.Push("diferencial");
        let c = await _formUserRef.current.ArrayLength("diferencial");
        await _formUserRef.current.Push("diferencial", c+1);
        setTimeout(() => {
            _formUserRef.current.SetObject("remove_diferencial", {visible: true});
        }, 200);
        Preload();
    }

    const click_remove_diferencial = async() => {
        let c = await _formUserRef.current.ArrayLength("diferencial");
        await _formUserRef.current.Push("diferencial", c-1);
        setTimeout(() => {
            _formUserRef.current.SetObject("remove_diferencial", {visible: ((c-1) > 1)});
        }, 200);
    }

    const click_add_concorrente = async() => {
        //await _formUserRef.current.Push("concorrente");
        let c = await _formUserRef.current.ArrayLength("concorrente");
        await _formUserRef.current.Push("concorrente", c+1);
        setTimeout(() => {
            _formUserRef.current.SetObject("remove_concorrente", {visible: true});
        }, 200);
        Preload();
    }


    const click_remove_concorrente = async() => {
        let c = await _formUserRef.current.ArrayLength("concorrente");
        await _formUserRef.current.Push("concorrente", c-1);
        setTimeout(() => {
            _formUserRef.current.SetObject("remove_concorrente", {visible: ((c-1) > 1)});
        }, 200);
    }

    const modelo = value => {
       WaitObject(_formUserRef.current).then(async() => {
            setInterval(() => {
                _formUserRef.current.SetObject("modelo_outro", {visible:(parseInt(value) === 13)});
                let _modelo = _formUserRef.current.GetObject("modelo").type.options.find(o => o.id === parseInt(value));
                _formUserRef.current.SetObject("div_model_description", {height: _modelo.height});
                document.getElementById("div_model_description").innerHTML = _modelo.description;
            }, 110);
       });
    }

    const socios = (value) => {
        _formUserRef.current.Push("socio", value);
    }


    const has_investimentos = (value) => {
        _formUserRef.current.SetObject("investimentos", {visible:(parseInt(value) === 1)});
        _formUserRef.current.SetObject("investimento", {visible:(parseInt(value) === 1)});
        _formUserRef.current.SetObject("investimento", {visible:(parseInt(value) === 1)});
        _formUserRef.current.SetObject("investimento", {visible:(parseInt(value) === 1)});
        _formUserRef.current.SetObject("investimento", {visible:(parseInt(value) === 1)});
    }


    const investimentos = value => {
        _formUserRef.current.Push("investimento", value);
    }

    const area = value => {
        _formUserRef.current.SetObject("outro", {visible:(parseInt(value) === 60)});
    }

    const maturidade = value => {
        if(parseInt(value) === 1) {
            _formUserRef.current.SetObject("maturidade_conceito", {visible:true});
            _formUserRef.current.SetObject("maturidade_desenvolvimento", {visible:false});
            _formUserRef.current.SetObject("maturidade_concluido", {visible:false});
            _formUserRef.current.SetObject("clientes", {visible:false});
            //_formUserRef.current.Value("clientes", 0);
        } else if(parseInt(value) === 2) {
            _formUserRef.current.SetObject("maturidade_conceito", {visible:false});
            _formUserRef.current.SetObject("maturidade_desenvolvimento", {visible:true});
            _formUserRef.current.SetObject("maturidade_concluido", {visible:false});
            _formUserRef.current.SetObject("clientes", {visible:false});
            // _formUserRef.current.Value("clientes", 0);

        } else if(parseInt(value) === 3) {
            _formUserRef.current.SetObject("maturidade_conceito", {visible:false});
            _formUserRef.current.SetObject("maturidade_desenvolvimento", {visible:false});
            _formUserRef.current.SetObject("maturidade_concluido", {visible:true});
            _formUserRef.current.SetObject("clientes", {visible:true});
        }
    }

    const escalavel = value => {
        if(parseInt(value) === 1) {
            _formUserRef.current.SetObject("escalavel_software", {disabled:false});
            _formUserRef.current.SetObject("escalavel_materiaprima", {disabled:false});
            _formUserRef.current.SetObject("escalavel_maodeobra", {disabled:false});
            _formUserRef.current.SetObject("escalavel_infraestrutura", {disabled:false});
            _formUserRef.current.SetObject("escalavel_investimento", {disabled:false});
            _formUserRef.current.SetObject("escalavel_publicidade", {disabled:false});
        } else if(parseInt(value) === 0) {
            _formUserRef.current.SetObject("escalavel_software", {disabled:true, checked: false});
            _formUserRef.current.SetObject("escalavel_materiaprima", {disabled:true, checked: false});
            _formUserRef.current.SetObject("escalavel_maodeobra", {disabled:true, checked: false});
            _formUserRef.current.SetObject("escalavel_infraestrutura", {disabled:true, checked: false});
            _formUserRef.current.SetObject("escalavel_investimento", {disabled:true, checked: false});
            _formUserRef.current.SetObject("escalavel_publicidade", {disabled:true, checked: false});
        }
    }

    const propriedade_dominio = value => {
        _formUserRef.current.SetObject("dominio", {disabled:!value, value: ""});
    }


    const concorrentes = value => {
        _formUserRef.current.SetObject("concorrente", {visible:parseInt(value)===1});
        _formUserRef.current.SetObject("add_concorrente", {visible:parseInt(value)===1});
    }


    const faturamento = value => {
        _formUserRef.current.SetObject("faturamento_valor", {visible:parseInt(value)===1});
    }


    const categoria = value => {
        LoadSubcategorias(value);
    }


    const OnFormLoaded = async(fields) => {
       /*Sleep(200).then(() => {
            Preload();
       });*/
       //console.log("OnFormLoaded");

       setPageLoading(true);

       WaitObject(_formUserRef.current).then(async() => {
            Preload();

            if(user.user_id !== "" && user.user_id !== null && user.user_id !== undefined) {
                let warmup_data = await new Request().Run("api/Warmup/Load?id="+user.user_id, "GET", "", user.token);
                if(warmup_data !== undefined && warmup_data.warmup_id !== null) {
                    warmupId.current = warmup_data.warmup_id;
                    console.log(warmup_data);
                    await _formUserRef.current.Load(warmup_data);
                    setShare(warmup_data.ativo===1);
                    
                }


                setPageLoading(false);
                setTimeout(() => {
                    LoadCategorias();
                    LoadSmartValuation();
                }, 100);
            }
       });
    }

    
    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        setPageLoading(true);

        //_formUserRef.current.Test("",""); setPageLoading(false);
        let result = await _formUserRef.current.Post("api/Warmup/Save", {warmup_id: warmupId.current}, user.token);
        setPageLoading(false);
        if(result === "error") {
            window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
            return;
        }
        window.scrollTo({top: 0, behavior: 'smooth'});
        //LoadSmartValuation();

        warmupId.current = result.warmup_id;

        setInfo({
            points: result.points.toFixed(2),
            points_max: result.points_max,
            percent: result.percent.toFixed(2),
            risco: result.risco.toFixed(2),
            valuation: result.valuation,
            projecao_anual: result.projecao_anual,
            custo_anual: result.custo_anual,
            investimento: result.investimento,
            projecao_clientes: result.projecao_clientes.toFixed(2),
            margem: result.margem.toFixed(2),
            open: result.abertura.toFixed(2)
        });

    }


    const _HandleShareClick = (e) => {
        setShare(e.target.checked);
        Promise.resolve(new Request().Run("api/Warmup/Share", "POST", {warmup_id: warmupId.current, share: e.target.checked}, user.token)).then((data) => { }).catch(() => { });
    }



    const HandlerImageError = (e) => {
        e.target.src = "images/db/0.jpg";
	}
    //#endregion Handlers


    //#region Functions
    const Preload = () => {
        let _modelo = _formUserRef.current.GetObject("modelo").type.options[0];
        _formUserRef.current.SetObject("div_model_description", {height: _modelo.height});
        document.getElementById("div_model_description").innerHTML = _modelo.description;
    }
    //#endregion Functions

    return (
        <div className="content-body SmartValuation">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-9 col-xxl-8 col-lg-12">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card profile-card">
                                    <div className="card-header flex-wrap border-0 pb-0">
                                        <h3 className="fs-18 text-black font-w400 mr-auto mb-2 pr-3">Preencha o máximo de campos</h3>
                                        <div className="d-sm-flex d-block">
                                            <div className="d-flex mr-5 align-items-center mb-2">
                                            <div className={'custom-control custom-switch toggle-switch text-right' + (warmupId.current === "" ? ' hide': ' ')} >
                                                    <input type="checkbox" className="custom-control-input" id="share" checked={share} onClick={_HandleShareClick} />
                                                    <label className="custom-control-label" htmlFor="share">Compartilhar?</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <form name="fuser" onSubmit={(event) => _HandleSubmit('reativar', event)} autoComplete="off" id="form-contact" className="" noValidate>
                                            <div className="row">
                                                <div className="col-md-12 pt-1 pb-1">
                                                    <FormGenerator ref={_formUserRef} name={'cadastro'} url={'/forms/warmup.json'} execRef={Exec}></FormGenerator>
                                                    <div style={{clear:'both'}}></div>
                                                    <center>
                                                        <hr />
                                                        <button id="btn_sv" className="btn btn-primary btn-rounded mb-2" type="submit">Enviar formulario</button>
                                                    </center>
                                                    <br />
                                                </div> 
                                            </div>
                                        </form>
                                    </div>
                                </div>

                               
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-xxl-4 col-lg-12">
						<div className="row">
							<div className="col-xl-12 col-lg-6">
								<div className="card  flex-lg-column flex-md-row ">
									<div className="card-body card-body  text-center border-bottom profile-bx">
										<div className="profile-image mb-4">
											<img src={'images/db/' + user.user_id + '.jpg?' + (Invalidator['profile_image'])}  className="rounded-circle" alt=""  onError={HandlerImageError}/>
										</div>
										<h4 className="fs-22 text-black mb-1">{uinfo.nome}</h4>
										<p className="mb-4">{uinfo.iam}</p>
										<div className="row">
											<div className="col-6">
												<div className="border rounded p-2">
													<h4 className="fs-22 text-black font-w600">{info.points}</h4>
													<span className="text-black">Pontos</span>
												</div>
											</div>
											<div className="col-6">
												<div className="border rounded p-2">
													<h4 className="fs-22 text-black font-w600">{info.points_max}</h4>
													<span className="text-black">Max Pontos</span>
												</div>
											</div>
										</div>
									</div>
									<div className="card-body activity-card">
										<div className="d-flex mb-3 align-items-center">
											<a className="contact-icon mr-3" ><i className="fa fa-money" aria-hidden="true"></i></a>
											<span className="text-black">Valuation <br /> {Currency(info.valuation)}</span>
										</div>
										<div className="d-flex mb-3 align-items-center">
											<a className="contact-icon mr-3" ><i className="fa fa-line-chart"></i></a>
											<span className="text-black">Projeção Anual <br /> {Currency(info.projecao_anual)}</span>
										</div>
										<div className="row text-center mt-2 mt-md-5">
											<div className="col-4 p-0">
												<div className="d-inline-block mb-2 relative donut-chart-sale">
													<span className="donut" data-peity='{ "fill": ["rgb(255, 142, 38)", "rgba(236, 236, 236, 1)"],   "innerRadius": 27, "radius": 10}'>{info.percent}/100</span>
													<small className="text-black">{info.percent}%</small>
												</div>
												<span className="d-block">Avaliação</span>
											</div>
											<div className="col-4 p-0">
												<div className="d-inline-block mb-2 relative donut-chart-sale">
													<span className="donut" data-peity='{ "fill": ["rgb(62, 168, 52)", "rgba(236, 236, 236, 1)"],   "innerRadius": 27, "radius": 10}'>{info.risco}/100</span>
													<small className="text-black">{info.risco}%</small>
												</div>
												<span className="d-block">Risco</span>
											</div>
											<div className="col-4 p-0">
												<div className="d-inline-block mb-2 relative donut-chart-sale">
													<span className="donut" data-peity='{ "fill": ["rgb(34, 172, 147)", "rgba(236, 236, 236, 1)"],   "innerRadius": 27, "radius": 10}'>{info.open}/100</span>
													<small className="text-black">{info.open}%</small>
												</div>
												<span className="d-block">Abertura</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-12 col-lg-6">
								<div className="card">	
									<div className="card-header border-0 pb-0">
										<h4 className="fs-20 text-black">Mais informações</h4>
										{/*<div className="dropdown float-right custom-dropdown mb-0">
											<div className="" data-toggle="dropdown" role="button" aria-expanded="false">
												<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
														<path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
														<path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
												</svg>
											</div>
											<div className="dropdown-menu dropdown-menu-right">
												<a className="dropdown-item" href="javascript:void(0);">Details</a>
												<a className="dropdown-item text-danger" href="javascript:void(0);">Cancel</a>
                                            </div>
										</div>*/}
									</div>
									<div className="card-body portfolios-card">
										<div className="d-flex mb-3 align-items-center rounded">
                                            <a className="contact-icon mr-3" style={{backgroundColor:'#3D6AD6', color:'#FFFFFF'}}><i className="fa fa-calendar"></i></a>
											<span className="font-w500">Custo anual<br /><small>{Currency(info.custo_anual)}</small></span>
										</div>
										<div className="d-flex mb-3 align-items-center rounded">
                                            <a className="contact-icon mr-3" style={{backgroundColor:'#EA518D', color:'#FFFFFF'}}><i className="fa fa-pie-chart"></i></a>
											<span className="font-w500">Investimento<br /><small>{Currency(info.investimento)}</small></span>
										</div>
										<div className="d-flex mb-3 align-items-center rounded">
                                            <a className="contact-icon mr-3" style={{backgroundColor:'#0073B1', color:'#FFFFFF'}}><i className="fa fa-street-view"></i></a>
											<span className="font-w500">Clientes Break Even<br /><small>{info.projecao_clientes}</small></span>
										</div>
										<div className="d-flex mb-3 align-items-center rounded">
                                            <a className="contact-icon mr-3"  style={{backgroundColor:'#FF0000', color:'#FFFFFF'}}><i className="fa fa-bar-chart"></i></a>
											<span className="font-w500">Margem<br /><small>{info.margem}%</small></span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
                </div>
            </div>
        </div>
    )
}