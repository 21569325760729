import React, { useEffect } from 'react';



export default function NavHeader() {


    useEffect(() => {
        window.$("#preloader").fadeOut(500);
        window.$("#main-wrapper").addClass("show");
        window.HeaderStart();
    }, [])


    return (
        <div className="nav-header">
            <a className="brand-logo">
               <img className="logo-abbr" src="images/logo1.png" alt="" />
               <img className="brand-title" src="images/logo2.png" alt="" />
            </a>
            <div className="nav-control">
                <div className="hamburger">
                    <span className="line"></span><span className="line"></span><span className="line"></span>
                </div>
            </div>
        </div>
    )
}