import React from 'react';
import './Terms.css';


export const Terms = () => { 

    return (
        <div className="Termos">
            <div className="terms">
                <h5>Autorização para a utilização de dados para a realização das conexões</h5> 
                <br />
                <p>Em observância à Lei nº. 13.709/18 – Lei Geral de Proteção de Dados Pessoais e demais normativas aplicáveis sobre proteção de Dados Pessoais, eu manifesto-me de forma informada, livre, expressa e consciente, no sentido de autorizar a TheLIGHT INNOVATION a realizar o tratamento de meus Dados Pessoais para as finalidades e de acordo com as condições aqui estabelecidas de mapeamento e prospecção de oportunidades de negócios ou projetos.</p>
                <p>Por fim, declaro ter lido e ter sido suficientemente informado sobre o conteúdo deste Termo e concordo com o tratamento dos meus Dados Pessoais aqui descrito de forma livre e esclarecida, em observância à Lei Geral de Proteção de Dados e às demais normativas sobre proteção de Dados Pessoais aplicáveis.</p>  
                <hr />
            </div>
        </div>
    )
}


