import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga';


const root = ReactDOM.createRoot(document.getElementById('root'));

ReactGA.initialize(
    [
        {
            trackingId: 'G-Q5TXN0TG59'
        }
    ],
    { debug: true, alwaysSendToDefaultTracker: false }
);

root.render(
    <>
        <App />
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
