/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext'
import { Request } from '../../../scripts/Request';
import { Sleep, Wait, WaitObject } from '../../../scripts/Wait';
import { HubObjects } from '../../../scripts/HubObjects';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import './index.css';
import moment from 'moment';
import { LinkContainer } from 'react-router-bootstrap';


export default function Notificacoes() {
    const component_name = "admin/notificacoes";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState({ word: "" });
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const listRef = useRef();
    const pageRef = useRef();
    const totalRef = useRef();
    const loadPrestineRef = useRef(false);
    const searchRef = useRef({ word: "" });
    const timerIdleRef = useRef(new Date());


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        WaitObject(user.nome).then(() => {
            Init();
        });

        return () => {
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(loading || pageLoading){
         window.$('#preloader').fadeIn(500);
        } else {
         window.$('#preloader').fadeOut(500);
        }
     }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
    const Init = () => {
        if(!loadPrestineRef.current) {
            loadPrestineRef.current = true;

            HubObjects.Exec("HEADER_TITLE", "Notificações");
            Readed();

            _HandlerSearchChange(null);
        }
    }
    //#endregion Init



    //#region Loaders
	const Readed = () => {
        Promise.resolve(new Request().Run("api/Notifications/Readed", "GET", "", user.token)).then((data) => { }).catch(() => {});
    }
	//#endregion Loaders




    //#region Handlers
    const _Handlechange = (event) => {
        setSearch(prev => prev = {[event.target.id]: event.target.value});
        searchRef.current = {[event.target.id]: event.target.value};
    }


    const _HandleDelete = (event, id) => {
        Promise.resolve(new Request().Run("api/Notifications/Delete", "POST", {notification_id: id}, user.token)).then((data) => {
            _HandlerSearchChange(event);
        }).catch(() => { });
        
    }


    const _HandleClear = (event) => {
        setSearch(prev => prev = {word: ""});
        searchRef.current = {word: ""};
        _HandlerSearchChange(event);
    }



    const _HandlerSearchChange = (event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setPageLoading(true);
        setHasMore(true);

        Promise.resolve(new Request().Run("api/Notifications/List?page=" + pageRef.current.page + "&interval=50&offset=" + pageRef.current.offset, "POST", { word: searchRef.current.word }, user.token))
            .then((data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                let _list = [...data.notifications];
                listRef.current = listRef.current.concat(_list);
                setList(listRef.current);
                setPageLoading(false);
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            });
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setPageLoading(true);

            Promise.resolve(new Request().Run("api/Notifications/List?page=" + pageRef.current.page + "&interval=50&offset=" + pageRef.current.offset, "POST", { word: searchRef.current.word }, user.token))
                .then((data) => {
                    let _list = [...data.notifications];
                    listRef.current = listRef.current.concat(_list);
                    setList(listRef.current);
                    setPageLoading(false);
                });
        } catch (e) { }
    }

    //#endregio Handlers



    return (
        <div className="content-body Notificacoes">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="d-flex align-items-center flex-wrap search-job bg-white rounded py-3 px-md-3 px-0 mb-4">
   
                            <div className="col-lg-12 d-md-flex">
                                <input id="word" className="form-control input-rounded mr-auto mb-md-0 mb-3" type="text" placeholder="buscar por título..." value={search.word} onChange={_Handlechange} />
                                <a className="bg-light btn btn-rounded text-primary mr-3" onClick={ _HandleClear}><i className="las la-trash scale5 mr-3"></i>LIMPAR</a>
                                <a className="btn btn-primary btn-rounded" onClick={ _HandlerSearchChange}><i className="las la-search scale5 mr-3"></i>BUSCAR</a>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                <InfiniteScroll
                                    dataLength={list.length}
                                    next={_fetchMoreData}
                                    hasMore={hasMore}
                                    loader={
                                        <p style={{ textAlign: "center" }}>
                                            <b>Carregando...</b>
                                        </p>
                                    }
                                    style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                    endMessage={
                                        <p style={{ textAlign: "center" }}>
                                            <b>Não há mais dados</b>
                                        </p>
                                    }>


                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th><strong>Origem</strong></th>
                                                <th><strong>Título</strong></th>
                                                <th><strong>Data</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((item, i) => (
                                                 <tr key={'notification' + i} >
                                                    <td>
                                                        <div className="media ">
                                                            {
                                                                item.icon === '' && item.letters !== '' && (
                                                                    <>
                                                                        {item.letters}
                                                                    </>
                                                                )
                                                            }

                                                            {
                                                                item.icon !== '' && item.letters === '' && (
                                                                    <i className={item.icon}></i>
                                                                )
                                                            }

                                                            {
                                                                item.img !== '' && item.letters === '' && item.icon === '' && (
                                                                    <img src={item.img} className="rounded-lg mr-2" width="24" alt=""/> 
                                                                )
                                                            }
                                                        </div>
                                                    </td>
                                                    
                                                    
                                                    <td><LinkContainer to={(item.url===''?'/Notificacoes':item.url)}><a>{item.content}</a></LinkContainer></td>
                                                    <td>{moment(item.date_created).format('DD/MM/YYYY HH:mm')}</td>
                                                    <td>
                                                        <div className="d-flex">
                                                            <a className="btn btn-danger shadow btn-xs sharp" onClick={(e) => _HandleDelete( e, item.notification_id)}><i className="fa fa-trash"></i></a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </InfiniteScroll>

                                    {/* 
                                    <table className="table table-responsive-md">
                                        <thead>
                                            <tr>
                                                <th className="width50">
                                                    <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                                                        <input type="checkbox" className="custom-control-input" id="checkAll" required="" />
                                                        <label className="custom-control-label" for="checkAll"></label>
                                                    </div>
                                                </th>
                                                <th><strong>ROLL NO.</strong></th>
                                                <th><strong>NAME</strong></th>
                                                <th><strong>Email</strong></th>
                                                <th><strong>Date</strong></th>
                                                <th><strong>Status</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                                                        <input type="checkbox" className="custom-control-input" id="customCheckBox2" required="" />
                                                        <label className="custom-control-label" for="customCheckBox2"></label>
                                                    </div>
                                                </td>
                                                <td><strong>542</strong></td>
                                                <td><div className="d-flex align-items-center"><img src="images/avatar/1.jpg" className="rounded-lg mr-2" width="24" alt=""/> <span className="w-space-no">Dr. Jackson</span></div></td>
                                                <td>example@example.com	</td>
                                                <td>01 August 2020</td>
                                                <td><div className="d-flex align-items-center"><i className="fa fa-circle text-success mr-1"></i> Successful</div></td>
                                                <td>
                                                    <div className="d-flex">
                                                        <a href="#" className="btn btn-primary shadow btn-xs sharp mr-1"><i className="fa fa-pencil"></i></a>
                                                        <a href="#" className="btn btn-danger shadow btn-xs sharp"><i className="fa fa-trash"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                                                        <input type="checkbox" className="custom-control-input" id="customCheckBox3" required="" />
                                                        <label className="custom-control-label" for="customCheckBox3"></label>
                                                    </div>
                                                </td>
                                                <td><strong>542</strong></td>
                                                <td><div className="d-flex align-items-center"><img src="images/avatar/2.jpg" className="rounded-lg mr-2" width="24" alt=""/> <span className="w-space-no">Dr. Jackson</span></div></td>
                                                <td>example@example.com	</td>
                                                <td>01 August 2020</td>
                                                <td><div className="d-flex align-items-center"><i className="fa fa-circle text-danger mr-1"></i> Canceled</div></td>
                                                <td>
                                                    <div className="d-flex">
                                                        <a href="#" className="btn btn-primary shadow btn-xs sharp mr-1"><i className="fa fa-pencil"></i></a>
                                                        <a href="#" className="btn btn-danger shadow btn-xs sharp"><i className="fa fa-trash"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                                                        <input type="checkbox" className="custom-control-input" id="customCheckBox4" required="" />
                                                        <label className="custom-control-label" for="customCheckBox4"></label>
                                                    </div>
                                                </td>
                                                <td><strong>542</strong></td>
                                                <td><div className="d-flex align-items-center"><img src="images/avatar/3.jpg" className="rounded-lg mr-2" width="24" alt=""/> <span className="w-space-no">Dr. Jackson</span></div></td>
                                                <td>example@example.com	</td>
                                                <td>01 August 2020</td>
                                                <td><div className="d-flex align-items-center"><i className="fa fa-circle text-warning mr-1"></i> Pending</div></td>
                                                <td>
                                                    <div className="d-flex">
                                                        <a href="#" className="btn btn-primary shadow btn-xs sharp mr-1"><i className="fa fa-pencil"></i></a>
                                                        <a href="#" className="btn btn-danger shadow btn-xs sharp"><i className="fa fa-trash"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    */}
                                </div>
                            </div>
                        </div>
                    </div>
                 </div>
            </div>
        </div>
    )
}