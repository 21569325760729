import React, { useEffect, useContext, useState, useRef } from 'react';
import { Context } from '../../Context/AuthContext';
import { Request } from '../../scripts/Request';
import { HubObjects } from '../../scripts/HubObjects';
import { WaitObject } from '../../scripts/Wait';
import { LinkContainer } from 'react-router-bootstrap';

import './index.css';


export default function Menu() {
    const component_name = "menu";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [list, setList] = useState([
        {
            "name": "Início",
            "link": "/Home",
            "icon": "flaticon-381-networking",
            "nodes": []
        }]);

    const timerIdleRef = useRef(new Date());


    //#region Effects
    useEffect(() => {
        WaitObject(user.nivel).then(() => {
            Init();
        });

        return () => {
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    /*useEffect(() => {
        window.MenuStart();
    }, []);*/
    //#endregion Effects


    //#region Init
    const Init = async() => {
        //console.log("Init");
        await Promise.resolve(new Request().Run("/json/menu_" + user.nivel + ".json", "GET", "", "", "", 0*1000))
            .then((data) => {
                setList(data)
            }).catch(() => {
            
            }).finally(() => {
                setTimeout(() => {
                    window.MenuStart();
                }, 100);
               
                
            })
    }
    //#endregion Init




    return (
    <div className="deznav">
        <div className="deznav-scroll">
            <ul className="metismenu" id="menu">
                {
                    list.map((item, i) => (
                        <li key={'itemmenu' + i} id={'itemmenu' + i}>
                            { 
                            item.link !== "" ? (      
                                <LinkContainer to={item.link} exact="true">
                                    <a className="has-arrow ai-icon mcursor" aria-expanded="false">
                                        <i className={item.icon}></i>
                                        <span className="nav-text">{item.name}</span>
                                    </a>        
                                </LinkContainer>
                            ) : (
                                <a className="has-arrow ai-icon mcursor" aria-expanded="false">
                                    <i className={item.icon}></i>
                                    <span className="nav-text">{item.name}</span>
                                </a> 
                            )}

                            <ul id={'subitems' + i} aria-expanded="false">
                                {item.nodes.map((subitem, j) => (
                                    <li key={'subitem' + j}>
                                        <LinkContainer to={subitem.link} exact="true">
                                            <a className="mcursor">{subitem.name}</a>
                                        </LinkContainer>
                                    </li>
                                ))}
                            </ul>    
                        </li>
                    ))
                }

                {/* 
                <li><a className="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                        <i className="flaticon-381-networking"></i>
                        <span className="nav-text">Dashboard</span>
                    </a>
                    <ul aria-expanded="false">
                        <li><a href="index.html">Dashboard Light</a></li>
                        <li><a href="index2.html">Dashboard Dark</a></li>
                        <li><a href="search-job.html">Search Job</a></li>
                        <li><a href="application.html">Application</a></li>
                        <li><a href="profile.html">Profile</a></li>
                        <li><a href="job-find.html">Job Find</a></li>
                        <li><a href="companies.html">Companies</a></li>
                        <li><a href="statistics.html">Statistics</a></li>
                    </ul>
                </li>
                <li><a className="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                        <i className="flaticon-381-television"></i>
                        <span className="nav-text">Apps</span>
                    </a>
                    <ul aria-expanded="false">
                        <li><a href="app-profile.html">Profile</a></li>
                        <li><a className="has-arrow" href="javascript:void()" aria-expanded="false">Email</a>
                            <ul aria-expanded="false">
                                <li><a href="email-compose.html">Compose</a></li>
                                <li><a href="email-inbox.html">Inbox</a></li>
                                <li><a href="email-read.html">Read</a></li>
                            </ul>
                        </li>
                        <li><a href="app-calender.html">Calendar</a></li>
                        <li><a className="has-arrow" href="javascript:void()" aria-expanded="false">Shop</a>
                            <ul aria-expanded="false">
                                <li><a href="ecom-product-grid.html">Product Grid</a></li>
                                <li><a href="ecom-product-list.html">Product List</a></li>
                                <li><a href="ecom-product-detail.html">Product Details</a></li>
                                <li><a href="ecom-product-order.html">Order</a></li>
                                <li><a href="ecom-checkout.html">Checkout</a></li>
                                <li><a href="ecom-invoice.html">Invoice</a></li>
                                <li><a href="ecom-customers.html">Customers</a></li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li><a className="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                        <i className="flaticon-381-controls-3"></i>
                        <span className="nav-text">Charts</span>
                    </a>
                    <ul aria-expanded="false">
                        <li><a href="chart-flot.html">Flot</a></li>
                        <li><a href="chart-morris.html">Morris</a></li>
                        <li><a href="chart-chartjs.html">Chartjs</a></li>
                        <li><a href="chart-chartist.html">Chartist</a></li>
                        <li><a href="chart-sparkline.html">Sparkline</a></li>
                        <li><a href="chart-peity.html">Peity</a></li>
                    </ul>
                </li>
                <li><a className="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                        <i className="flaticon-381-internet"></i>
                        <span className="nav-text">Bootstrap</span>
                    </a>
                    <ul aria-expanded="false">
                        <li><a href="ui-accordion.html">Accordion</a></li>
                        <li><a href="ui-alert.html">Alert</a></li>
                        <li><a href="ui-badge.html">Badge</a></li>
                        <li><a href="ui-button.html">Button</a></li>
                        <li><a href="ui-modal.html">Modal</a></li>
                        <li><a href="ui-button-group.html">Button Group</a></li>
                        <li><a href="ui-list-group.html">List Group</a></li>
                        <li><a href="ui-media-object.html">Media Object</a></li>
                        <li><a href="ui-card.html">Cards</a></li>
                        <li><a href="ui-carousel.html">Carousel</a></li>
                        <li><a href="ui-dropdown.html">Dropdown</a></li>
                        <li><a href="ui-popover.html">Popover</a></li>
                        <li><a href="ui-progressbar.html">Progressbar</a></li>
                        <li><a href="ui-tab.html">Tab</a></li>
                        <li><a href="ui-typography.html">Typography</a></li>
                        <li><a href="ui-pagination.html">Pagination</a></li>
                        <li><a href="ui-grid.html">Grid</a></li>

                    </ul>
                </li>
                <li><a className="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                        <i className="flaticon-381-heart"></i>
                        <span className="nav-text">Plugins</span>
                    </a>
                    <ul aria-expanded="false">
                        <li><a href="uc-select2.html">Select 2</a></li>
                        <li><a href="uc-nestable.html">Nestedable</a></li>
                        <li><a href="uc-noui-slider.html">Noui Slider</a></li>
                        <li><a href="uc-sweetalert.html">Sweet Alert</a></li>
                        <li><a href="uc-toastr.html">Toastr</a></li>
                        <li><a href="map-jqvmap.html">Jqv Map</a></li>
                        <li><a href="uc-lightgallery.html">Light Gallery</a></li>
                    </ul>
                </li>
                <li><a href="widget-basic.html" className="ai-icon" aria-expanded="false">
                        <i className="flaticon-381-settings-2"></i>
                        <span className="nav-text">Widget</span>
                    </a>
                </li>
                <li><a className="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                        <i className="flaticon-381-notepad"></i>
                        <span className="nav-text">Forms</span>
                    </a>
                    <ul aria-expanded="false">
                        <li><a href="form-element.html">Form Elements</a></li>
                        <li><a href="form-wizard.html">Wizard</a></li>
                        <li><a href="form-editor-summernote.html">Summernote</a></li>
                        <li><a href="form-pickers.html">Pickers</a></li>
                        <li><a href="form-validation-jquery.html">Jquery Validate</a></li>
                    </ul>
                </li>
                <li><a className="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                        <i className="flaticon-381-network"></i>
                        <span className="nav-text">Table</span>
                    </a>
                    <ul aria-expanded="false">
                        <li><a href="table-bootstrap-basic.html">Bootstrap</a></li>
                        <li><a href="table-datatable-basic.html">Datatable</a></li>
                    </ul>
                </li>
                <li><a className="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                        <i className="flaticon-381-layer-1"></i>
                        <span className="nav-text">Pages</span>
                    </a>
                    <ul aria-expanded="false">
                        <li><a href="page-register.html">Register</a></li>
                        <li><a href="page-login.html">Login</a></li>
                        <li><a className="has-arrow" href="javascript:void()" aria-expanded="false">Error</a>
                            <ul aria-expanded="false">
                                <li><a href="page-error-400.html">Error 400</a></li>
                                <li><a href="page-error-403.html">Error 403</a></li>
                                <li><a href="page-error-404.html">Error 404</a></li>
                                <li><a href="page-error-500.html">Error 500</a></li>
                                <li><a href="page-error-503.html">Error 503</a></li>
                            </ul>
                        </li>
                        <li><a href="page-lock-screen.html">Lock Screen</a></li>
                    </ul>
                </li>*/}
            </ul>
            
            {/*<div className="copyright">
                <p><strong>Jobie Dashboard</strong> ©All Rights Reserved</p>
                <p>by DexignZone</p>
            </div>*/}
        </div>
    </div>
    )
}