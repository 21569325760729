import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { Cache } from './scripts/Cache';

const firebaseConfig = {
    apiKey: "AIzaSyCrKzjXKsPK_HzWt-2q34jYfAtmlSMymww",
    authDomain: "opportunitymap-8eef7.firebaseapp.com",
    projectId: "opportunitymap-8eef7",
    storageBucket: "opportunitymap-8eef7.appspot.com",
    messagingSenderId: "968396498685",
    appId: "1:968396498685:web:43c5b54e98c5f65fd5c094",
    measurementId: "G-Q5TXN0TG59"
  };

  
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const analytics = getAnalytics(app);
const publicKey = "BGvk8ccn_iGUztkJTd2lLjxy-K1cwJcuDl2IEF4pUxEUusLa_G-jIuXuSP-qezjk383_mqmH4tHKmd3ETr4S0kg";

export const requestForToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await getToken(messaging, { vapidKey: publicKey });
    Cache.Set("firebase_token", currentToken);
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};


export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage((payload) => {
      resolve(payload);
    });
  });


 