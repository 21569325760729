import React, { useContext, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import $ from 'jquery';
import { Context } from '../../Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Request } from '../../scripts/Request';
import { GetServer } from '../../scripts/Loadbalancer';
import { WaitObject } from '../../scripts/Wait';
import './Login.css';
import { Terms } from './Terms';



const Login = () => {
    const { loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [state, setState] = useState({
        login: "",
        password: "",
        code2fa: ""
    });
    const [pageLoading, setPageLoading] = useState(false);
    const [pwdState, setPwdState] = useState({ message: "", color: "#FFFFFF" });
    const [step, setStep] = useState(1);
    const [recoverStep, setRecoverStep] = useState(1);
    const [saveable, setSaveable] = useState(false);
    const [categorias, setCategorias] = useState([]);
    const [subcategorias, setSubcategorias] = useState([]);
    const [estados, setEstados] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [qr2fa, setQr2fa] = useState("");

    const [form, setForm]= useState({
        origin: "",
        lookfor: 1,
        iam: 1,
        categoria: 1,
        subcategoria: 1,
        uf: 26,
        cidade: 1,
        nome:"",
        email:"",
        senha: "",
        resenha: ""
    });

    const [recover, setRecover] = useState({
        email: "",
        code: "",
        senha: "",
        resenha: "",
        token: ""
    });

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const loadedRef = useRef(false);
    const navigate = useNavigate();




    //#region Effects
    useEffect(() => {
        GetServer();

        if(!loadedRef.current) {
            loadedRef.current = true;
            handleLogout(true);
            window.$(".login_ex").hide();
            Init();
        }
    }, []);


    useEffect(() => {
        if(loading || pageLoading) {
            window.$('#preloader').fadeIn(500);
        } else {
            window.$('#preloader').fadeOut(500);
        }
     }, [loading, pageLoading]);


    useEffect(() => {
        checkSaveable();
    }, [form]);
    //#endregion Effects


     //#region Core
     const Init = () => {
        const _userpwd = localStorage.getItem('userpwd');

        if (_userpwd) {
            let _u = JSON.parse(_userpwd);
            setState(_u);
        }

        LoadCategorias();
        LoadUfs();
    };
    //#endregion Core


    //#region Loaders
    const LoadCategorias = () => {
        //console.log("LoadCategorias");
        //setPageLoading(true);
        Promise.resolve(new Request().Run("api/Auth/Categorias", "GET", "", "", 24*60*1000))
        .then((data) => {
            //setPageLoading(false);
            if(data.toString() !== "error") {
                setCategorias(data);
                LoadSubcategorias(1);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch((e) => {
            //setPageLoading(false);
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            console.log(e);
        });
    }


    const LoadSubcategorias = (id) => {
        //setPageLoading(true);
        Promise.resolve(new Request().Run("api/Auth/Subcategorias?cat="+id, "GET", "", "", 24*60*1000))
        .then((data) => {
            //setPageLoading(false);
            if(data.toString() !== "error") {
                if(data.length > 0) {
                    setSubcategorias(data);
                } else {
                    setSubcategorias([{id: 0, name: "Geral"}]);
                }
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            //setPageLoading(false);
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }


    const LoadUfs = () => {
        Promise.resolve(new Request().Run("/json/estados.json", "GET", "", "", 24*60*1000))
        .then((data) => {
            setEstados(data);
            LoadCidades(26)
            if(data.toString() !== "error") {
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }



    const LoadCidades = (uf_codigo) => {
        Promise.resolve(new Request().Run("api/Auth/Cidades?uf_codigo=" + uf_codigo, "GET", "", "", 24*60*1000))
        .then((data) => {
            setCidades(data);
            if(data.toString() !== "error") {
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }


    const SendCode = () => {
        let _recover = Object.assign({}, recover);

        if(_recover.email.indexOf("@") > 0) {
            setPageLoading(true);
            Promise.resolve(new Request().Run("api/Auth/SendCode", "POST",_recover, "", 0*1000))
            .then((data) => {
                setPageLoading(false);
                if(data.toString() !== "error") {
                    setRecoverStep(3);
                } else {
                    window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
                }
            }).catch(() => {
            
            });
        }
    }


    const CheckCode = () => {
        let _recover = Object.assign({}, recover);
        if(_recover.email.indexOf("@") > 0) {
            setPageLoading(true);
            Promise.resolve(new Request().Run("api/Auth/CheckCode", "POST", _recover, "", 0*1000))
            .then((data) => {
                setPageLoading(false);
                if(data.toString() !== "error") {
                    setRecoverStep(4);
                    _recover.token = data.token;
                    setRecover(_recover);
                } else {
                    window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
                }
            }).catch(() => {
            
            });
        }
    }


    const SaveNewPwd = () => {
        let _recover = Object.assign({}, recover);

        Promise.resolve(new Request().Run("api/Auth/SaveNewPwd", "POST", _recover, "", 0*1000))
        .then((data) => {
            window.swal("Sucesso", "Nova senha salva com sucesso", "success");
            navigate("/Home");
        }).catch(() => {
        
        });

    }


    const _handleSave = () => {
        let _form = Object.assign({}, form );
        Promise.resolve(new Request().Run("api/Auth/Cadastro", "POST", _form, "", 0*1000))
        .then((data) => {
            if(data === "loaded") {
                window.swal("Sucesso", "Cadastro realizado com sucesso", "success");
                _handleClear();

                navigate("/");
            } else if(data === "exists") {
                window.swal("Alerta", "E-mail já cadastrado", "error");
            } else {
                window.swal("Alerta", "Ocorreu um erro no cadastro", "error");
            }
        }).catch(() => {
        
        });
    }
    //#endregion Loaders


    //#region Handlers
    const handleChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }


    const handleRecoverChange = (e) => {
        const { name, value } = e.target;

        setRecover(prev => ({
            ...prev,
            [name]: value
        }))
    }


    const _handleLogin = async(event) => {
        event.preventDefault();
        let _state = Object.assign({}, state);
        let _userpwd = localStorage.getItem('userpwd');

        setState({ login: _state.login, password: _state.password });

        localStorage.setItem('userpwd', JSON.stringify({ login: _state.login, password: _state.password }));

        let qr = await handleLogin(_state);
        //console.log(qr);
        if(qr && qr.tfa) setQr2fa(qr.tfa);
    };


    const _handleClick = (event, target, id) => {
        setForm(prev =>({...prev, [target]: id}));

        if(target === "origin") {
            if(id < 3) 
            {
                setStep(2)
            } 
            else if(id === 3) {
                setStep(3);
            } 
            else if(id === 4) {
                setStep(4);
            }
        }
    }

    const _handleClear = () => {
        setForm({
            origin: "",
            lookfor: 1,
            iam: 1,
            nome:"",
            email:"",
            senha: "",
            resenha: ""
        })

        setStep(1);
    }


    const _handleFormChange = (event) => {
        setForm(prev =>({...prev, [event.target.name]: event.target.value}));

        //forceUpdate();
        //checkSaveable();

        if(event.target.name === "resenha" || event.target.name === "senha") {
            StrengthChecker(event.target.value);
        }

        if(event.target.name === "categoria") {
            LoadSubcategorias(event.target.value);
        }


        if(event.target.name === "uf") {
            LoadCidades(event.target.value);
        }
    }


    const _handleRecoverClick = (event) => {
        if(recoverStep === 1) {
            setRecoverStep(2);
            return;
        }

        setRecover(prev =>({...prev, [event.target.name]: event.target.value}));
    }


    const _handleSendCode = () => {
        let _recover = Object.assign({}, recover );

        Promise.resolve(new Request().Run("api/Auth/SendCode", "POST", _recover, "", 0*1000))
        .then((data) => {
            if(data === "loaded") {
                window.swal("Sucesso", "Verifique o código enviado ao seu e-mail", "success");
                setRecoverStep(3);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
        
        });
    }



    const _handleCheckCode = () => {
        let _recover = Object.assign({}, recover );

        Promise.resolve(new Request().Run("api/Auth/CheckCode", "POST", _recover, "", 0*1000))
        .then((data) => {
            if(data !== "error") {
                setRecover(prev =>({...prev, token: data}));
                window.swal("Sucesso", "Código confirmado, insira uma nova senha.", "success");
                setRecoverStep(4);
            } else {
                window.swal("Alerta", "Código inválido, insira o código enviado no seu e-mail", "error");
            }
        }).catch(() => {
        
        });
    }


    const _handleSaveNewPwd = () => {
        let _recover = Object.assign({}, recover );

        if(_recover.senha === _recover.resenha && _recover.senha.length > 5) {
            Promise.resolve(new Request().Run("api/Auth/SaveNewPwd", "POST", _recover, "", 0*1000))
            .then((data) => {
                if(data === "loaded") {
                    window.swal("Sucesso", "Senha atualizada com sucesso!", "success");
                    setRecoverStep(1);
                }
            }).catch(() => {
            
            });
        } else {
            window.swal("Alerta", "Escolha uma senha com 6 ou mais caracteres!", "error");
        }
    }


    const handleBackStep = (step) => {
        setRecoverStep(step);
    }
    //#endregion Handlers



    //#region Functions
    const StrengthChecker = (pass) => {
        var score = scorePassword(pass);

        if (score >= 75) {
            setPwdState({ message: "Senha Forte", color: "#008800" });
        } else if (score > 50) {
            setPwdState({ message: "Senha Aceitável", color: "#0000FF" });
        } else if (score >= 25) {
            setPwdState({ message: "Senha Fraca", color: "#FF0000" });
        } else if (score <= 5) {
            setPwdState({ message: "", color: "#FFFFFF" });
        }
    }


    const scorePassword = (pass) => {
        var score = 0;
        if (!pass)
            return score;

        // award every unique letter until 5 repetitions
        var letters = new Object();
        for (var i = 0; i < pass.length; i++) {
            letters[pass[i]] = (letters[pass[i]] || 0) + 1;
            score += 5.0 / letters[pass[i]];
        }

        // bonus points for mixing it up
        var variations = {
            digits: /\d/.test(pass),
            lower: /[a-z]/.test(pass),
            upper: /[A-Z]/.test(pass),
            nonWords: /\W/.test(pass),
        }

        var variationCount = 0;
        for (var check in variations) {
            variationCount += (variations[check] == true) ? 1 : 0;
        }
        score += (variationCount - 1) * 10;

        return parseInt(score);
    }


    const checkSaveable = () => {
        let _form = Object.assign({}, form);
        //console.log(_form.nome, _form.email, _form.senha, _form.resenha, (_form.nome.length > 3 && _form.email.length > 5 && _form.email.indexOf("@") > 1 && _form.senha.length > 3 && _form.senha === _form.resenha))

        setSaveable(_form.nome.length > 3 && _form.email.length > 5 && _form.email.indexOf("@") > 1 && _form.senha.length > 3 && _form.senha === _form.resenha);
    }

    //#endregion Functions



    return (
            <div className="row Login" >
                
    
                <div className="my-content-body">
                    <div className="container-fluid">
                    
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="profile card card-body px-3 pt-3 pb-3">
                                    <div className="profile-head">
                                        <div className="photo-content">
                                            <div className="cover-photo"></div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4">
                                <div className="card">'
                                    <div className={'card-body ' + (recoverStep===1?'':'hide')}>
                                        <div className="profile-blog mb-5">
                                            <h3 className="text-primary d-inline">Já tenho cadastro</h3>
                                            <h6>Faça seu login abaixo</h6>
                                        </div>
    
                                        <div className="profile-statistics mb-5">
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input id="login" type="email" placeholder="" className="form-control" value={state.login} onChange={handleChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Password</label>
                                                <input id="password" type="password" placeholder="" className="form-control"  value={state.password} onChange={handleChange}/>
                                            </div>     
                                         
                                            <div className={'form-group ' + (qr2fa === '2fa' ? '' : 'hide')}>

                                                <label>Autenticação de 2 fatores</label>
                                                <input id="code2fa" type="text" placeholder="" className="form-control"  value={state.code2fa} onChange={handleChange}/>
                                                <small><a style={{color:'#0000ff', float:'right'}} href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=pt_BR&gl=US&pli=1" target="_blank">Google Authenticator</a></small>
                                                <div style={{clear:'both'}} /> 
                                            </div> 
    
                                            <a className="btn btn-primary btn_round" onClick={_handleLogin}>  Entrar  </a>   
                                            <a className="btn" onClick={_handleRecoverClick}>  Esqueci minha senha  </a>                
                                        </div>
                                    </div>



                                    <div className={'card-body ' + (recoverStep===2?'':'hide')}>
                                        <div className="profile-blog mb-5">
                                            <h3 className="text-primary d-inline"> <i className="la la-angle-double-left mr-2 cursor" onClick={() => handleBackStep(1)}></i> Recuperar acesso</h3>
                                        </div>
    
                                        <div className="profile-statistics mb-5">
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input name="email" type="email" placeholder="" className="form-control" value={recover.email} onChange={handleRecoverChange}/>
                                            </div>
                        
                                            <a className="btn btn-primary btn_round" onClick={_handleSendCode}>  Enviar código de recuperação  </a>                
                                        </div>
                                    </div>



                                    <div className={'card-body ' + (recoverStep===3?'':'hide')}>
                                        <div className="profile-blog mb-5">
                                            <h3 className="text-primary d-inline">Recuperar acesso</h3>
                                        </div>
    
                                        <div className="profile-statistics mb-5">
                                            <div className="form-group">
                                                <label>Digite o código de recuperação</label>
                                                <input name="code" type="text" placeholder="" className="form-control" value={recover.code} onChange={handleRecoverChange}/>
                                            </div>
                        
                                            <a className="btn btn-primary btn_round" onClick={_handleCheckCode}>  Validar código de recuperação  </a>                
                                        </div>
                                    </div>



                                    <div className={'card-body ' + (recoverStep===4?'':'hide')}>
                                        <div className="profile-blog mb-5">
                                            <h3 className="text-primary d-inline">Criar nova senha</h3>
                                        </div>
    
                                        <div className="profile-statistics mb-5">
                                            <div className="form-group">
                                                <label>Nova senha</label>
                                                <input name="senha" type="password" placeholder="" className="form-control" value={recover.senha} onChange={handleRecoverChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Redigitar nova senha</label>
                                                <input name="resenha" type="password" placeholder="" className="form-control"  value={recover.resenha} onChange={handleRecoverChange}/>
                                            </div>     
    
                                            <a className="btn btn-primary btn_round" onClick={_handleSaveNewPwd}>  Salvar </a>                
                                        </div>
                                    </div>
                                </div>
                            </div>
    
    
                            <div className="col-xl-8">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="profile-tab">
                                            {/* Opções */}
                                            <div className={step===1?'':'hide'}>
                                                <ul className="nav nav-tabs">
                                                    <li className="nav-item"><a href="#my-posts" data-toggle="tab" className="nav-link active show"><h2>Projetos em inovação</h2></a>
                                                    </li>
                                                </ul>
                                                <div id="#step1" className={form.origin===''?'':'hide'}>
                                                    <div id="my-posts" className="tab-pane fade active show">
                                                        <br />
                                                        <div className="row">
                                                            <div className="col-xxl-6 col-lg-6 col-sm-6">
                                                                <div className="widget-stat card bg-danger cursor" onClick={(event) => _handleClick(event, 'origin', 0)}>
                                                                    <div className="card-body  p-4">
                                                                        <div className="media">
                                                                            <span className="mr-3">
                                                                                <i className="flaticon-381-diamond"></i>
                                                                            </span>
                                                                            <div className="media-body text-white text-right">
                                                                            <p className="mb-1">Cadastre Produto/ideia</p>
                                                                                <h3 className="text-white">Tenho uma solução</h3>   
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xxl-6 col-lg-6 col-sm-6">
                                                                <div className="widget-stat card bg-success cursor" onClick={(event) => _handleClick(event, 'origin', 1)}>
                                                                    <div className="card-body p-4">
                                                                        <div className="media">
                                                                            <span className="mr-3">
                                                                                <i className="flaticon-381-settings-2"></i>
                                                                            </span>
                                                                            <div className="media-body text-white text-right">
                                                                                <p className="mb-1">Cadastre Produto/serviço</p>
                                                                                <h3 className="text-white">Preciso de uma solução</h3>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
    
                                                        <div className="row">
                                                            <div className="col-xxl-6 col-lg-6 col-sm-6">
                                                                <div className="widget-stat card bg-info cursor"  onClick={(event) => _handleClick(event, 'origin', 2)}>
                                                                    <div className="card-body p-4">
                                                                        <div className="media">
                                                                            <span className="mr-3">
                                                                                <i className="flaticon-381-user-7"></i>
                                                                            </span>
                                                                            <div className="media-body text-white text-right">
                                                                                <p className="mb-1">Busca por talentos</p>
                                                                                <h3 className="text-white">Encontre um profissional</h3>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/*<div className="col-xxl-6 col-lg-6 col-sm-6">
                                                                <div className="widget-stat card bg-warning">
                                                                    <div className="card-body p-4">
                                                                        <div className="media">
                                                                            <span className="mr-3">
                                                                                <i className="flaticon-381-calendar-1"></i>
                                                                            </span>
                                                                            <div className="media-body text-white text-right">
                                                                                <p className="mb-1">Chamadas públicas</p>
                                                                                <h3 className="text-white">Participe de Editais</h3>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>*/}
                                                        </div>
                                                        
                                                    </div>
    
    
                                                    
                                                    <br />  
                                                    <ul className="nav nav-tabs">
                                                        <li className="nav-item"><a href="#my-posts" data-toggle="tab" className="nav-link active show"><h2>Oportunidades</h2></a>
                                                        </li>
                                                    </ul>    
                                                    <br />           
                                                    <div className="row">
                                                        <div className="col-xxl-6 col-lg-6 col-sm-6">
                                                            <div className="widget-stat card bg-primary cursor" onClick={(event) => _handleClick(event, 'origin', 3)}>
                                                                <div className="card-body p-4">
                                                                    <div className="media">
                                                                        <span className="mr-3">
                                                                            <i className="la la-graduation-cap"></i>
                                                                        </span>
                                                                        <div className="media-body text-white text-right">
                                                                            <p className="mb-1">Banco de talentos</p>
                                                                            <h3 className="text-white">Cadastre seu perfil</h3>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
    
                                                        <div className="col-xxl-6 col-lg-6 col-sm-6">
                                                            <div className="widget-stat card bg-dark cursor" onClick={(event) => _handleClick(event, 'origin', 4)}>
                                                                <div className="card-body p-4">
                                                                    <div className="media">
                                                                        <span className="mr-3">
                                                                            <i className="la la-dollar"></i>
                                                                        </span>
                                                                        <div className="media-body text-white text-right">
                                                                            <p className="mb-1">Projetos / negócios</p>
                                                                            <h3 className="text-white">Opções de investimento</h3>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>          
                                                    
                                                    
                                                </div>

                                            </div>

                                             {/* Empresas/ Startups / Instituições */}                   
                                            <div className={step===2?'':'hide'}>
                                                <ul className="nav nav-tabs">
                                                    <li className="nav-item"><a onClick={_handleClear} data-toggle="tab" className="nav-link active show"><h2> <i className="la la-angle-double-left mr-2"></i> Empresas / Startups / Instituições</h2></a>
                                                    </li>
                                                </ul>
                                                <div>
                                                    <div id="my-posts" className="tab-pane fade active show">
                                                        <br />

                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <label>Represento um(a)</label>
                                                                    <div className="form-group">
                                                                        <select name="iam" className="form-control form-control-lg" value={form.iam} onChange={_handleFormChange}>
                                                                            <option value="1">Empresa</option>
                                                                            <option value="2">Startup</option>
                                                                            <option value="3">Instituição</option>
                                                                            <option value="4">Empreendedor</option>
                                                                            <option value="9">Outro</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>  


                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <label>Busco por</label>
                                                                    <div className="form-group">
                                                                        <select name="lookfor" className="form-control form-control-lg" value={form.lookfor} onChange={_handleFormChange}>
                                                                            { form.origin===0 && (<>
                                                                            <option value="1">Investimentos/Financiamentos</option>
                                                                            <option value="2">Parceiros estratégicos</option>
                                                                            <option value="3">Penetração de mercado</option>
                                                                            <option value="9">Outro</option>
                                                                            </>) }

                                                                            { form.origin===1 && (<>
                                                                            <option value="1">Solução em software</option>
                                                                            <option value="2">Solução em produto físico</option>
                                                                            <option value="3">Desenvolvimento de projeto de software</option>
                                                                            <option value="4">Desenvolvimento de produto físico</option>
                                                                            <option value="9">Outro</option>
                                                                            </>) }


                                                                            { form.origin===2 && (<>
                                                                            <option value="1">Desenvolvedor de software</option>
                                                                            <option value="2">Engenheiro de área específica</option>
                                                                            <option value="3">Profissional jurídico</option>
                                                                            <option value="4">Profissional contábil</option>
                                                                            <option value="5">Profissional em marketing e mídias sociais</option>
                                                                            <option value="9">Outro</option>
                                                                            </>) }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> 


                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Área principal de atuação</label>
                                                                    <div className="form-group">
                                                                        <select name="categoria" className="form-control form-control-lg" value={form.categoria} onChange={_handleFormChange}>
                                                                            {
                                                                                Array.isArray(categorias) && categorias.length > 0 && categorias.map((item, i) => (
                                                                                    <option key={'categoria'+i} value={item.id}>{item.name}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Subárea de atuação</label>
                                                                    <div className="form-group">
                                                                        <select name="subcategoria" className="form-control form-control-lg" value={form.subcategoria} onChange={_handleFormChange}>
                                                                            {
                                                                                Array.isArray(subcategorias) && subcategorias.length > 0 && subcategorias.map((item, i) => (
                                                                                    <option key={'subcategoria'+i} value={item.id}>{item.name}</option>
                                                                                ))
                                                                            }

                                                                            {
                                                                               Array.isArray(subcategorias) && subcategorias.length === 0 && categorias.length > 0 && (<option value={0}>{categorias[0].name}</option>)
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>  

                                                        <hr />                 

                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Nome ou Razão social</label>
                                                                    <input name="nome" type="text" className="form-control" placeholder="" value={form.nome} onChange={_handleFormChange} />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>E-mail <small>(para login)</small></label>
                                                                    <input name="email" type="email" className="form-control" placeholder="" value={form.email} onChange={_handleFormChange} />
                                                                </div>
                                                            </div> 

                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Senha </label> <span style={{ color: pwdState.color, float: 'right' }}><small>{pwdState.message}</small></span>
                                                                    <input name="senha" type="password" className="form-control" placeholder="" value={form.senha} onChange={_handleFormChange}/>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Repetir senha</label>
                                                                    <input name="resenha" type="password" className="form-control" placeholder=""  value={form.resenha} onChange={_handleFormChange}/>
                                                                    <small className={(form.senha.length >= 6 && form.resenha.length >= 1 && form.senha !== form.resenha) ? '' : 'hide'} style={{ color: 'red', float:'right' }}> As senhas não são iguais!</small>
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Estado <small>(UF)</small></label>
                                                                    <select name="uf" className="form-control form-control-lg" value={form.uf} onChange={_handleFormChange}>
                                                                        {
                                                                             Array.isArray(estados) && estados.length > 0 && estados.map((item, i) => (
                                                                                <option key={'uf'+i} value={item.id}>{item.name}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Cidade</label>
                                                                    <select name="cidade" className="form-control form-control-lg" value={form.cidade} onChange={_handleFormChange}>
                                                                        {
                                                                            Array.isArray(cidades) && cidades.length > 0 && cidades.map((item, i) => (
                                                                                <option key={'cidade'+i} value={item.id}>{item.name}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div> 

                                                        </div>   

                                                        <hr />   

                                                        <Terms />

                                                        <button className={'btn btn-primary btn_round ' + (saveable?'':'hide')} onClick={_handleSave}> Salvar Cadastro  </button>  
                                                    </div>
                                                 </div>
                                            </div>


                                            {/* Profissional Especialista */}                   
                                            <div className={step===3?'':'hide'}>
                                                <ul className="nav nav-tabs">
                                                    <li className="nav-item"><a onClick={_handleClear} data-toggle="tab" className="nav-link active show"><h2> <i className="la la-angle-double-left mr-2"></i> Banco de Taletos</h2></a>
                                                    </li>
                                                </ul>
                                                <div>
                                                    <div id="my-posts" className="tab-pane fade active show">
                                                        <br />
                                                        <hr />                 

                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Nome completo</label>
                                                                    <input name="nome" type="text" className="form-control" placeholder="" value={form.nome} onChange={_handleFormChange} />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>E-mail <small>(para login)</small></label>
                                                                    <input name="email" type="email" className="form-control" placeholder="" value={form.email} onChange={_handleFormChange} />
                                                                </div>
                                                            </div> 

                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Senha </label> <span style={{ color: pwdState.color, float: 'right' }}><small>{pwdState.message}</small></span>
                                                                    <input name="senha" type="password" className="form-control" placeholder="" value={form.senha} onChange={_handleFormChange}/>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Repetir senha</label>
                                                                    <input name="resenha" type="password" className="form-control" placeholder=""  value={form.resenha} onChange={_handleFormChange}/>
                                                                    <small className={(form.senha.length >= 6 && form.resenha.length >= 1 && form.senha !== form.resenha) ? '' : 'hide'} style={{ color: 'red', float:'right' }}> As senhas não são iguais!</small>
                                                                </div>
                                                            </div>
                                                        </div>   

                                                        <hr />

                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Área principal de atuação</label>
                                                                    <div className="form-group">
                                                                        <select name="categoria" className="form-control form-control-lg" value={form.categoria} onChange={_handleFormChange}>
                                                                            {
                                                                                Array.isArray(categorias) && categorias.length > 0 && categorias.map((item, i) => (
                                                                                    <option key={'categoria'+i} value={item.id}>{item.name}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Subárea de atuação</label>
                                                                    <div className="form-group">
                                                                        <select name="subcategoria" className="form-control form-control-lg" value={form.subcategoria} onChange={_handleFormChange}>
                                                                            {
                                                                                Array.isArray(subcategorias) && subcategorias.length > 0 && subcategorias.map((item, i) => (
                                                                                    <option key={'subcategoria'+i} value={item.id}>{item.name}</option>
                                                                                ))
                                                                            }

                                                                            {
                                                                               subcategorias && subcategorias.length === 0 && categorias.length > 0 && (<option value={0}>{categorias[0].nome}</option>)
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>  


                                                        <hr /> 

                                                        <Terms />  

                                                        <button className={'btn btn-primary btn_round ' + (saveable?'':'hide')} onClick={_handleSave}> Salvar Cadastro  </button>  
                                                    </div>
                                                 </div>
                                            </div>


                                            {/* h2>Investidores */}                   
                                            <div className={step===4?'':'hide'}>
                                                <ul className="nav nav-tabs">
                                                    <li className="nav-item"><a onClick={_handleClear}  data-toggle="tab" className="nav-link active show"><h2> <i className="la la-angle-double-left mr-2"></i> Investidores </h2></a>
                                                    </li>
                                                </ul>
                                                <div>
                                                <div id="my-posts" className="tab-pane fade active show">
                                                        <br />
                                                        <hr />                 

                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Nome completo</label>
                                                                    <input name="nome" type="text" className="form-control" placeholder="" value={form.nome} onChange={_handleFormChange} />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>E-mail <small>(para login)</small></label>
                                                                    <input name="email" type="email" className="form-control" placeholder="" value={form.email} onChange={_handleFormChange} />
                                                                </div>
                                                            </div> 

                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Senha </label> <span style={{ color: pwdState.color, float: 'right' }}><small>{pwdState.message}</small></span>
                                                                    <input name="senha" type="password" className="form-control" placeholder="" value={form.senha} onChange={_handleFormChange}/>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Repetir senha</label>
                                                                    <input name="resenha" type="password" className="form-control" placeholder=""  value={form.resenha} onChange={_handleFormChange}/>
                                                                    <small className={(form.senha.length >= 6 && form.resenha.length >= 1 && form.senha !== form.resenha) ? '' : 'hide'} style={{ color: 'red', float:'right' }}> As senhas não são iguais!</small>
                                                                </div>
                                                            </div>
                                                        </div>   

                                                        <hr />

                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Área principal de interesse</label>
                                                                    <div className="form-group">
                                                                        <select name="categoria" className="form-control form-control-lg" value={form.categoria} onChange={_handleFormChange}>
                                                                            {
                                                                                Array.isArray(categorias) && categorias.length > 0 && categorias.map((item, i) => (
                                                                                    <option key={'categoria'+i} value={item.id}>{item.name}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Subárea de interesse</label>
                                                                    <div className="form-group">
                                                                        <select name="subcategoria" className="form-control form-control-lg" value={form.subcategoria} onChange={_handleFormChange}>
                                                                            {
                                                                                Array.isArray(subcategorias) && subcategorias.length > 0 && subcategorias.map((item, i) => (
                                                                                    <option key={'subcategoria'+i} value={item.id}>{item.name}</option>
                                                                                ))
                                                                            }

                                                                            {
                                                                               subcategorias && subcategorias.length === 0 && categorias.length > 0 && (<option value={0}>{categorias[0].nome}</option>)
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>  

                                                        <hr />

                                                         <Terms />     

                                                        <button className={'btn btn-primary btn_round ' + (saveable?'':'hide')} onClick={_handleSave}> Salvar Cadastro  </button>  
                                                    </div>
                                                 </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
               
            </div>
       
    )
};

export default Login;