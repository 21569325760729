/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { InvalidatorContext } from '../../../Context/InvalidatorContext';
import { Sleep, WaitObject } from '../../../scripts/Wait';
import { Request } from '../../../scripts/Request';
import { Currency } from '../../../scripts/StringUtils';
import { HubObjects } from '../../../scripts/HubObjects';
import { FormGenerator } from '../../Form/FormGenerator';
import { UploadService } from '../../../scripts/UploadService';
import { ServerURL } from '../../../scripts/Loadbalancer';
import ReactGA from 'react-ga';
import { Button } from "react-bootstrap";
import { useParams } from 'react-router-dom';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import './index.css';

import { MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';




export default function Cadastro() {
    const component_name = "admin/cadastro";
    const { user, loading, authenticated, handleLogin, handleLogout, Refresh } = useContext(Context);
    const { Invalidator, Invalidate, InvalidateStream } = useContext(InvalidatorContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [showFotoEditModal, setShowFotoEditModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const [video, setVideo] = useState("");
    const _formUserRef = useRef();
    const _formLoadedRef = useRef();
    const loadPrestineRef = useRef(false);
    const timerIdleRef = useRef(new Date());
    const [tfa, setTfa] = useState(false);
    const [qr2fa, setQr2fa] = useState("");
    const [code2fa, setCode2fa] = useState("");


    const data_origin = useRef([
        {id: 0, data:[
           {id: 1, name: "Investimentos/Financiamentos"},
           {id: 2, name: "Parceiros estratégicos"}, 
           {id: 3, name: "Penetração de mercado"}, 
           {id: 9, name: "Outro"} 
        ]},
        {id: 1, data:[
            {id: 1, name: "Solução em software"},
            {id: 2, name: "Solução em produto físico"}, 
            {id: 3, name: "Desenvolvimento de projeto de software"}, 
            {id: 4, name: "Desenvolvimento de produto físico"}, 
            {id: 9, name: "Outro"} 
         ]},
         {id: 2, data:[
             {id: 1, name: "Desenvolvedor de software"},
             {id: 2, name: "Engenheiro de área específica"}, 
             {id: 3, name: "Profissional jurídico"}, 
             {id: 4, name: "Profissional contábil"}, 
             {id: 5, name: "Profissional em marketing e mídias sociais"},
             {id: 9, name: "Outro"} 
          ]}
    ]);

    const data_modalidade = useRef([
        { id: 1, name: "Investidor anjo" },
        { id: 2, name: "Venture capital" },
        { id: 3, name: "Seed money" },
        { id: 4, name: "Smart money" },
        { id: 9, name: "Outro" }
      ])

    const [uinfo, setUinfo] = useState({
		nome: "",
		iam: ""
	});

    const [info, setInfo] = useState({ });

    const [imageList, setImageList] = useState([]);
    const _uploadedImages = useRef([]);
    const [image, setImage] = useState("");
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState();
    const onChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const hiddenFileInput = useRef(null);


    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        WaitObject(user.nome).then(() => {
            Init();
        });

        return () => {
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(loading || pageLoading){
         window.$('#preloader').fadeIn(500);
        } else {
         window.$('#preloader').fadeOut(500);
        }
     }, [loading, pageLoading]);


     useEffect(() => {
        LoadInfo();
        Init();
     }, [user]);
    //#endregion Effects


    //#region Init
    const Init = async() => {
        HubObjects.Exec("HEADER_TITLE", "Meu Cadastro");
        await InvalidateStream('profile_image');

        /*setTimeout(async() => {
            await Invalidate('profile_image');
        }, 10000);*/

        let _nome = "";
		let _arr_name = user.nome.split(' ');
		if(_arr_name.length > 1) {
			_nome = _arr_name[0] + " " + _arr_name[1];
		} else {
			_nome = _arr_name[0];
		}

        let _iam = "Não definido";

		if(user.origin < 3) {
			switch(user.iam) { 
				case 1:
					_iam = "Empresa"
					break;
				case 2:
					_iam = "Startup"
					break;
				case 3:
					_iam = "Instituição"
					break;
				case 4:
					_iam = "Empreendedor"
					break;
				case 9:
					_iam = "Não definido"
					break;
			}
		} else if(user.origin === 3) {
            _iam = "Profissional"
        } else if(user.origin === 4) {
            _iam = "Investidor"
        }


		setUinfo(prev => ({
			...prev,
			nome: _nome,
			iam: _iam
		}));

        HubObjects.Exec("HEADER_IAM",{nome: _nome, iam: _iam});

        setTimeout(() => {
            window.dzChartlist.load();
        }, 100);
        
    }
    //#endregion Init


    //#region Loaders
    const LoadCategorias = () => {
        Promise.resolve(new Request().Run("api/Auth/Categorias", "GET", "", "", 1*24*60*1000))
        .then((data) => {
            let v = _formUserRef.current.GetDataValue("categoria");
            _formUserRef.current.Select("categoria", data);
            //_formUserRef.current.Value("categoria", (v && v !== undefined && v !== null ? v : data[0].id));
            LoadSubcategorias((v && v !== undefined && v !== null ? v : data[0].id));
        }).catch(() => {
            
        });
    }


    const LoadSubcategorias = (id) => {
        Promise.resolve(new Request().Run("api/Auth/Subcategorias?cat="+id, "GET", "", "", 1*24*60*1000))
        .then((data) => {
            //let v = _formUserRef.current.GetDataValue("subcategoria");
            if(data.length > 0) {
                _formUserRef.current.Select("subcategoria", data, "");
                //_formUserRef.current.Value("subcategoria", (v && v !== undefined && v !== null ? v : data[0].id));
            } else {
                _formUserRef.current.Select("subcategoria", [{id: 0, name: "Geral"}], "");
                //_formUserRef.current.Value("subcategoria", (v && v !== undefined && v !== null ? v : data[0].id));
            }
        }).catch(() => {
           
        });
    }


    const LoadUfs = (uf = 26) => {
        Promise.resolve(new Request().Run("/json/estados.json", "GET", "", "", 0*24*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            await _formUserRef.current.Select("uf", data);
            LoadCidades(uf)

            if(data.toString() !== "error") {
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }


    const LoadCidades = (uf_codigo) => {
        Promise.resolve(new Request().Run("api/Auth/Cidades?uf_codigo=" + uf_codigo, "GET", "", "", 0*24*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            await _formUserRef.current.Select("cidade", data);
            if(data.toString() !== "error") {
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }


    const LoadInfo = () => {
        if(user.origin === 0) {
            Promise.resolve(new Request().Run("api/Warmup/SmartValuationI?q=" + user.iam, "GET", "", user.token, 0*1000)).then((data) => { setInfo(data); setTimeout(() => { window.dzChartlist.load();}, 100);}).catch(() => {});
        } else if(user.origin === 1) {
            Promise.resolve(new Request().Run("api/Needs/InfoI?q=" + user.iam, "GET", "", user.token, 0*1000)).then((data) => { setInfo(data); setTimeout(() => { window.dzChartlist.load();}, 100);}).catch(() => { });
        } else if(user.origin === 2) {
            Promise.resolve(new Request().Run("api/Headhunter/InfoI?id="+ user.iam, "GET", "", user.token, 0*1000)).then((data) => { setInfo(data);setTimeout(() => {window.dzChartlist.load(); }, 100); }).catch(() => { });
        } else if(user.origin === 3) {
            Promise.resolve(new Request().Run("api/Professional/InfoI?id="+user.iam, "GET", "", user.token, 0*1000)).then((data) => {setInfo(data); setTimeout(() => { window.dzChartlist.load(); }, 100);}).catch(() => { });
        } else if(user.origin === 4) {
            Promise.resolve(new Request().Run("api/Investor/InfoI?id="+user.iam, "GET", "", user.token, 0*1000)).then((data) => { setInfo(data); setTimeout(() => { window.dzChartlist.load();}, 100);}).catch(() => { });
        }
    }
    //#endregion Loaders


    //#region Form Handlers
    const categoria = value => {
        LoadSubcategorias(value);
    }


    const uf = value => {
        LoadCidades(value);
    }


    const origin = async value => {
        //console.log(value);
        await _formUserRef.current.SetObject("iam", {visible:(parseInt(value) < 3)});
        await _formUserRef.current.SetObject("lookfor", {visible:(parseInt(value) < 3)});
        await _formUserRef.current.SetObject("step1", {title:"Empresas / Startups / Instituições"});
        await _formUserRef.current.Value("iam", "1");
        await _formUserRef.current.Value("lookfor", "1");

        if(parseInt(value) < 3) {
            await _formUserRef.current.Select("lookfor", data_origin.current[value].data);   
        } 
        else if(parseInt(value)=== 3)
        {
            await _formUserRef.current.SetObject("step1", {title:"Cadastro de profissional especialista"});
            await _formUserRef.current.Value("iam", "");
            await _formUserRef.current.Value("lookfor", "");
        }
        else if(parseInt(value) === 4)
        {
            await _formUserRef.current.SetObject("step1", {title:"Cadastro de Investidor"});
            await  _formUserRef.current.Value("iam", "");
            await  _formUserRef.current.Value("lookfor", "");
        }
    }


    const pitch = value => {
        const videoIdRegex = /(?<=v=)[^&\s]+|(?<=\.be\/)[^&\s]+/gi;
        const match = value.match(videoIdRegex);
        const vid = match ? match[0] : null;

        if(vid !== null) {
            setVideo(vid);
        } else {
            setVideo("");
        }
    }



    const OnFormLoaded = async(fields) => {
       setPageLoading(true);

       WaitObject(_formUserRef.current).then(async() => {
            await _formUserRef.current.Select("lookfor", data_origin.current[user.iam].data);
            
            if(user.user_id !== "" && user.user_id !== null && user.user_id !== undefined) {
                let user_data = await new Request().Run("api/Cadastro/Load?id="+user.user_id, "GET", "", user.token);
                if(user_data !== undefined && user_data.user_id !== null) {
                    await _formUserRef.current.Load(user_data);
                    LoadUfs(user_data.uf);
                    setTfa(user_data.tfa);
                }

                setPageLoading(false);
                window.scrollTo({top: 0, behavior: 'smooth'});
                setTimeout(async() => {
                    LoadCategorias();
                    LoadInfo();
                    await _formUserRef.current.Value("senha", "a");
                    await _formUserRef.current.Value("resenha", "");
                    setTimeout(async() => {
                        await _formUserRef.current.Value("senha", "");
                    }, 100);
                }, 100);
            }
       });
       
    }

    
    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        setPageLoading(true);

        //_formUserRef.current.Test("","");
        let result = await _formUserRef.current.Post("api/Cadastro/Save", {user_id: user.user_id}, user.token);
        setPageLoading(false);

        await _formUserRef.current.Value("senha", "");
        await _formUserRef.current.Value("resenha", "");

        if(result === "error") {
            window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
            return;
        } else if(result === "exists") {
            window.swal("Alerta", "E-mail já registrado por outro usuário", "error");
            return;
        }

        window.scrollTo({top: 0, behavior: 'smooth'});

        await Refresh();
    }


    const _HandleTFAClick = (e) => {
        setTfa(e.target.checked);
        Promise.resolve(new Request().Run("api/Cadastro/TFA", "POST", {tfa: e.target.checked}, user.token)).then((data) => { }).catch(() => { });
    }


    const _HandleTFAQRClick = (e) => {
        setTfa(e.target.checked);
        Promise.resolve(new Request().Run("api/Cadastro/QRTFA", "POST", {tfa: e.target.checked}, user.token)).then((data) => { 
            setQr2fa(data)
        }).catch(() => { });
    }


    const _HandleChangeTFA = (e) => {
        setCode2fa(e.target.value);
        if(e.target.value.length === 6) {
            Promise.resolve(new Request().Run("api/Cadastro/TFACheck", "POST", {tfa: e.target.value}, user.token)).then((data) => { 
                setCode2fa("");
                if(data.toString() === "loaded") {
                    setQr2fa({tfa: "", code:""});
                    setTfa(true);
                    window.swal("Sucesso", "Autenticação de 2 fatores ativada", "success");
                } else {
                    setTfa(false);
                    window.swal("Alerta", "Código para ativação incorreto", "error");
                }
                
            }).catch(() => { });
        }
    }


    const HandlerImageError = (e) => {
        e.target.src = "images/db/0.jpg";
	}


    const _handlerUploadClick = () => {
        hiddenFileInput.current.click();
    }


    const _HandleUpload = event => {
        UploadImages(event);
    }


    const UploadImages = async(event) => {
        setPageLoading(true);
        let upload = new UploadService();
        let c = 0;

        upload.OnEnd = (result) => {
            let arr = JSON.parse(result.img);
            _uploadedImages.current.push({foto: arr[0], edited: 0});
            c++;

            if(c === event.target.files.length) {
                setImageList(_uploadedImages.current);
                setPageLoading(false);
            }
        }

        for(let i=0; i<event.target.files.length; i++) {
            upload.Queue(event.target.files[i]);
        }

        await upload.PopUpload("https://painel.opportunitymap.com.br/api/Cadastro/UploadFotos", user.token);
    }


    const _handleDeleteFoto = async(foto) => {
        window.swal({
            title: "Tem certeza?",
            text: "Deseja realmente apagar essa foto?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Sim, tenho certeza!',
            cancelButtonText: "Não, cancelar!",
            closeOnConfirm: true,
            closeOnCancel: true
         },
         function(isConfirm){
            if (isConfirm){
                foto.foto = foto.foto.replace("https://painel.oportunitymap.com.br/images/db/", "");
                setPageLoading(true);
        
                Promise.resolve(new Request().Run("api/Cadastro/DeleteFoto", "POST", { foto: foto.foto }, user.token))
                    .then(async(data) => {
                        let ilist = [...imageList];
                        let index = ilist.findIndex(f => f.foto === foto.foto);
                        ilist.splice(index, 1);
                        _uploadedImages.current = ilist;
                        setImageList(ilist);
                        setPageLoading(false);
                    }).catch(() => {
                        setPageLoading(false);
                    });
            } else {
               
            }
         });


        
    }
    //#endregion Handlers


    //#region Functions
    const Tempo = (m) => {
        switch(parseInt(m)) {
            case 0:
                return "Imediato";
            case 1:
                return "Até 1 mês";
            case 2:
                return "Até 2 meses";
            case 3:
                return "Até 3 meses";
            case 6:
                return "Até 6 meses";
            case 12:
                return "Até 12 meses";
            case 24:
                return "Até 24 meses";
            case 98:
                return "Mais que 24 meses";
            case 99:
                return "Indefinido";
            default:
                return "Indefinido";
        }
    }


    const GetModalidade = (index) => {
        try {
            return data_modalidade.current.find(v => v.id === index).name;
        } catch(e) {
            return "Não preenchido";
        }
    }
    //#endregion Functions


    //#region Modal
    const _handleCloseFotoEditModal= async() => {
        setShowFotoEditModal(false);
    }
    //#endregion Modal


    //#region Cropper
    const _handleEditFoto = (foto) => {
        setShowFotoEditModal(true);

        if(foto !== "" ||  foto !== null ||  foto !== undefined) foto = { foto: "0.jpg" };
        setSelectedImage(foto);

        let request = new XMLHttpRequest();
        request.open('GET', ServerURL("images/db/")+foto.foto, true);
        request.responseType = 'blob';
        request.onload = function() {
            let reader = new FileReader();
            reader.onload =  function(e){
                //console.log(e.target.result)
                setImage(e.target.result);
            };
            reader.readAsDataURL(request.response);
        };
        request.send();
        
        
    }


    const getCropData = (foto) => {
        if (typeof cropper !== "undefined") {
            foto = foto.replace(ServerURL("images/db/"), "");

            let ilist = [...imageList];
            //ilist.find(f => f.foto === foto).edited = 1;

            setPageLoading(true);

            let cropped = cropper.getCroppedCanvas({ 
                width: 500,
                height: 500,
                minWidth: 500,
                minHeight: 500,
                maxWidth: 500,
                maxHeight: 500,
                fillColor: '#fff',
                imageSmoothingEnabled: true,
                imageSmoothingQuality: 'high'});

            setCropData(cropper.getCroppedCanvas().toDataURL());

        
            let blob = cropped.toBlob(async function (blob) {
                let file = new File([blob], foto, { type: 'image/jpeg' });
                let upload = new UploadService();
                upload.Data = {foto: foto}
                upload.OnEnd = async(image) => {
                    setShowFotoEditModal(false);
                    setPageLoading(false);
                    //HubObjects.Exec("HEADER_UPDATE_IMG");
                    await Invalidate('profile_image');
                    /*forceUpdate();*/
                }
                await upload.Upload(file, ServerURL("api/Cadastro/Crop"), user.token);
            }, 'image/jpeg');
        }
    }
    //#endregion Cropper


    return (
        <div className="content-body Cadastro">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-9 col-xxl-8 col-lg-12">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card profile-card">                         
                                    <div className="card-body">
                                        <form name="fuser" onSubmit={(event) => _HandleSubmit('reativar', event)} autoComplete="off" id="form-contact" className="" noValidate>
                                            <div className="row">
                                                <div className="col-md-12 pt-1 pb-1">
                                                    <FormGenerator ref={_formUserRef} name={'cadastro'} url={'/forms/cadastro.json'} execRef={Exec}></FormGenerator>
                                                    <div style={{clear:'both'}}></div>
                                                    <center>
                                                        { video && video !== "" &&
                                                            (<>
                                                            <iframe width="560" height="315" src={'https://www.youtube.com/embed/'+video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                                            </>)
                                                        }

                                                        <hr />
                                                        <button id="btn_sv" className="btn btn-primary btn-rounded mb-2" type="submit">Salvar informações</button>
                                                    </center>
                                                    <br />
                                                </div> 
                                            </div>
                                        </form>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-xxl-4 col-lg-12">
						<div className="row">
							<div className="col-xl-12 col-lg-6">
								<div className="card  flex-lg-column flex-md-row ">
									<div className="card-body card-body  text-center border-bottom profile-bx">
										<div className="profile-image mb-4">
											<img id="user_image" src={'images/db/' + user.user_id + '.jpg?' +  (Invalidator['profile_image'])}  className="rounded-circle" alt="" onError={HandlerImageError} onClick={_handleEditFoto}/>
                                        </div>
                                        <div className="user_camera pointer" onClick={_handleEditFoto}>
                                            <img src="images/camera.png" alt="" />
                                        </div>
										<h4 className="fs-22 text-black mb-1">{uinfo.nome}</h4>
										<p className="mb-4">{uinfo.iam}</p>
										<div className="row">
                                            { user.origin === 0 && (
                                            <>
                                                <div className="col-6">
                                                    <div className="border rounded p-2">
                                                        <h4 className="fs-22 text-black font-w600">{info && info.points && (info.points)}</h4>
                                                        <span className="text-black">Pontos</span>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="border rounded p-2">
                                                        <h4 className="fs-22 text-black font-w600">{info.points_max}</h4>
                                                        <span className="text-black">Max Pontos</span>
                                                    </div>
                                                </div>
                                            </>
                                            )}
										</div>
									</div>

									<div className="card-body activity-card">
                                        { user.origin === 0 && (
                                            <>
                                            <div className="d-flex mb-3 align-items-center">
                                                <a className="contact-icon mr-3"><i className="fa fa-money" aria-hidden="true"></i></a>
                                                <span className="text-black">Valuation <br /> {info && info.valuation && (Currency(info.valuation))}</span>
                                            </div>
                                            <div className="d-flex mb-3 align-items-center">
                                                <a className="contact-icon mr-3"><i className="fa fa-line-chart"></i></a>
                                                <span className="text-black">Projeção Anual <br /> {info &&  info.valuation && (Currency(info.projecao_anual))}</span>
                                            </div>
                                            <div className="row text-center mt-2 mt-md-5">
                                                <div className="col-4 p-0">
                                                    <div className="d-inline-block mb-2 relative donut-chart-sale">
                                                        <span className="donut" data-peity='{ "fill": ["rgb(255, 142, 38)", "rgba(236, 236, 236, 1)"],   "innerRadius": 27, "radius": 10}'>{info && info.percent && (info.percent)}/100</span>
                                                        <small className="text-black">{info && info.percent && (info.percent)}%</small>
                                                    </div>
                                                    <span className="d-block">Avaliação</span>
                                                </div>
                                                <div className="col-4 p-0">
                                                    <div className="d-inline-block mb-2 relative donut-chart-sale">
                                                        <span className="donut" data-peity='{ "fill": ["rgb(62, 168, 52)", "rgba(236, 236, 236, 1)"],   "innerRadius": 27, "radius": 10}'>{info && info.risco && (info.risco)}/100</span>
                                                        <small className="text-black">{info && info.risco && (info.risco)}%</small>
                                                    </div>
                                                    <span className="d-block">Risco</span>
                                                </div>
                                                <div className="col-4 p-0">
                                                    <div className="d-inline-block mb-2 relative donut-chart-sale">
                                                        <span className="donut" data-peity='{ "fill": ["rgb(34, 172, 147)", "rgba(236, 236, 236, 1)"],   "innerRadius": 27, "radius": 10}'>{info && info.abertura && (info.abertura)}/100</span>
                                                        <small className="text-black">{info && info.abertura && (info.abertura)}%</small>
                                                    </div>
                                                    <span className="d-block">Abertura</span>
                                                </div>
                                            </div>
                                        </>
                                        )}


                                        { user.origin === 1 && (
                                        <>
                                           <div className="d-flex mb-3 align-items-center">
											    <a className="contact-icon mr-3" ><i className="fa fa-money" aria-hidden="true"></i></a>
											    <span className="text-black">Orçamento aberto <br /> {info.orcamento > 0 && Currency(info.orcamento)} {info.orcamento === 0 && 'Não disponível'}</span>
                                            </div>
                                            <div className="d-flex mb-3 align-items-center">
                                                <a className="contact-icon mr-3" ><i className="fa fa-line-chart"></i></a>
                                                <span className="text-black">Projeção mensal <br /> {info.mensal > 0 && Currency(info.mensal)} { info.mensal === 0 && 'Não disponível'}</span>
                                            </div>
                                        </>
                                        )}


                                        { (user.origin === 2 || user.origin === 3) && (
                                        <>
                                           <div className="row text-center mt-2 mt-md-1">
                                                <div className="col-4 p-0">
                                                    <div className="d-inline-block mb-2 relative donut-chart-sale">
                                                        <span className="donut" data-peity='{ "fill": ["rgb(255, 142, 38)", "rgba(236, 236, 236, 1)"],   "innerRadius": 27, "radius": 10}'>{info.formacao}/100</span>
                                                        <small className="text-black">{info && info.formacao && (info.formacao)}%</small>
                                                    </div>
                                                    <span className="d-block">Formação</span>
                                                </div>
                                                <div className="col-4 p-0">
                                                    <div className="d-inline-block mb-2 relative donut-chart-sale">
                                                        <span className="donut" data-peity='{ "fill": ["rgb(62, 168, 52)", "rgba(236, 236, 236, 1)"],   "innerRadius": 27, "radius": 10}'>{info && info.skill && (info.skill)}/100</span>
                                                        <small className="text-black">{info && info.skill && (info.skill)}%</small>
                                                    </div>
                                                    <span className="d-block">Habilidades</span>
                                                </div>
                                                <div className="col-4 p-0">
                                                    <div className="d-inline-block mb-2 relative donut-chart-sale">
                                                        <span className="donut" data-peity='{ "fill": ["rgb(34, 172, 147)", "rgba(236, 236, 236, 1)"],   "innerRadius": 27, "radius": 10}'>{info && info.idioma && (info.idioma)}/100</span>
                                                        <small className="text-black">{info && info.idioma && (info.idioma)}%</small>
                                                    </div>
                                                    <span className="d-block">Idiomas</span>
                                                </div>
										    </div>
                                        </>
                                        )}


                                        { user.origin === 4 && (
                                        <>
                                           <div className="d-flex mb-3 align-items-center">
                                                <a className="contact-icon mr-3" ><i className="fa fa-money" aria-hidden="true"></i></a>
                                                <span className="text-black">Capital disponível<br /> {info && info.valor && (Currency(info.valor))}</span>
                                            </div>
                                            <div className="d-flex mb-3 align-items-center">
                                                <a className="contact-icon mr-3" ><i className="fa fa-line-chart"></i></a>
                                                <span className="text-black">Modalidade <br /> {info && info.modalidade && (GetModalidade(info.modalidade))}</span>
                                            </div>
                                        </>
                                        )}
									</div>
								</div>
							</div>

							<div className="col-xl-12 col-lg-6">
								<div className="card">	
									<div className="card-header border-0 pb-0">
										<h4 className="fs-20 text-black">Mais informações</h4>
									</div>
									<div className="card-body portfolios-card">
                                        { user.origin === 0 && (
                                        <>
                                            <div className="d-flex mb-3 align-items-center rounded">
                                                <a className="contact-icon mr-3" style={{backgroundColor:'#3D6AD6', color:'#FFFFFF'}}><i className="fa fa-calendar"></i></a>
                                                <span className="font-w500">Custo anual<br /><small>{info && info.custo_anual && (Currency(info.custo_anual))}</small></span>
                                            </div>
                                            <div className="d-flex mb-3 align-items-center rounded">
                                                <a className="contact-icon mr-3" style={{backgroundColor:'#EA518D', color:'#FFFFFF'}}><i className="fa fa-pie-chart"></i></a>
                                                <span className="font-w500">Investimento<br /><small>{info && info.investimento && (Currency(info.investimento))}</small></span>
                                            </div>
                                            <div className="d-flex mb-3 align-items-center rounded">
                                                <a className="contact-icon mr-3" style={{backgroundColor:'#0073B1', color:'#FFFFFF'}}><i className="fa fa-street-view"></i></a>
                                                <span className="font-w500">Clientes Break Even<br /><small>{info && info.projecao_clientes && (info.projecao_clientes)}</small></span>
                                            </div>
                                            <div className="d-flex mb-3 align-items-center rounded">
                                                <a className="contact-icon mr-3"  style={{backgroundColor:'#FF0000', color:'#FFFFFF'}}><i className="fa fa-bar-chart"></i></a>
                                                <span className="font-w500">Margem<br /><small>{info && info.margem && (info.margem)}%</small></span>
                                            </div>
                                        </>
                                        )}


                                        { user.origin === 1 && (
                                        <>
                                            <div className="d-flex mb-3 align-items-center rounded">
                                                <a className="contact-icon mr-3" style={{backgroundColor:'#3D6AD6', color:'#FFFFFF'}}><i className="fa fa-calendar"></i></a>
											    <span className="font-w500">Período de contrato<br /><small>{info && info.tempo && (Tempo(info.tempo))}</small></span>
                                            </div>
                                            <div className="d-flex mb-3 align-items-center rounded">
                                                <a className="contact-icon mr-3" style={{backgroundColor:'#EA518D', color:'#FFFFFF'}}><i className="fa fa-pie-chart"></i></a>
                                                <span className="font-w500">Avaliação<br /><small>{info && info.avaliacao && (info.avaliacao)}%</small></span>
                                            </div>
                                        </>
                                        )}


                                        { (user.origin === 2 || user.origin === 3) && (
                                        <>
                                            <div className="d-flex mb-3 align-items-center rounded">
                                                <a className="contact-icon mr-3" style={{backgroundColor:'#EA518D', color:'#FFFFFF'}}><i className="fa fa-pie-chart"></i></a>
                                                <span className="font-w500">Avaliação<br /><small>{info && info.avaliacao && (info.avaliacao)}%</small></span>
                                            </div>
                                        </>
                                        )}


                                        { (user.origin === 4) && (
                                        <>
                                            <div className="d-flex mb-3 align-items-center rounded">
                                            <a className="contact-icon mr-3" style={{backgroundColor:'#3D6AD6', color:'#FFFFFF'}}><i className="fa fa-eye"></i></a>
											<span className="font-w500">Visibilidade<br /><small>{info && info.visivel && (info.visivel === 1 ? 'Capital oculto':'Capital visível')}</small></span>
                                        </div>
										<div className="d-flex mb-3 align-items-center rounded">
                                            <a className="contact-icon mr-3" style={{backgroundColor:'#EA518D', color:'#FFFFFF'}}><i className="fa fa-pie-chart"></i></a>
											<span className="font-w500">Avaliação<br /><small>{info && info.avaliacao && (info.avaliacao)}%</small></span>
										</div>
										<div className="d-flex mb-3 align-items-center rounded">
                                            <a className="contact-icon mr-3"  style={{backgroundColor:'#0073B1', color:'#FFFFFF'}}><i className="fa fa-bar-chart"></i></a>
											<span className="font-w500">Porcentagem<br /><small>{info && info.porcentagem && (info.porcentagem)}%</small></span>
										</div>
                                        </>
                                        )}
									</div>
								</div>
							</div>

                            <div className="col-xl-12 col-lg-6">
								<div className="card">	
									<div className="card-header border-0 pb-0">
                                        <div className={'custom-control custom-switch toggle-switch _text-right'} >
                                            <input type="checkbox" className="custom-control-input" id="tfa" checked={tfa} onClick={_HandleTFAQRClick} />
                                            <label className="custom-control-label" htmlFor="tfa">Autenticação de 2 fatores</label>
                                        </div>
                                    </div>
                                    <div className="card-header border-0 pb-0 pt-0">
                                        <small><a style={{color:'#0000ff', float:'right'}} href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=pt_BR&gl=US&pli=1" target="_blank">Google Authenticator</a></small>
                                    </div>
									<div className="card-body portfolios-card">
                                       
                                        {
                                            qr2fa.tfa && qr2fa.tfa !== "" && (<>
                                                <center>
                                                    <img src={qr2fa.tfa} alt="" style={{width:'100%', height:'auto'}}/>
                                                    <br />
                                                    <input id="code2fa" type="text" placeholder="" className="form-control"  value={code2fa} onChange={_HandleChangeTFA}/>

                                                    <br />
                                                </center>
                                                <small>
                                                <p>Escaneie o QR Code no app Google Authenticator e digite o código fornecido para ativação. Após ativado em cada novo login no sistema será pedido um código atualizado.</p>
                                                <p>Caso desinstale o app, você deve acessar a recuperação de senha para desativar a autenticação de 2 fatores</p>
                                                </small>
                                                </> )
                                        }
                                      

                                        
									</div>
                                   
								</div>

                                  
							</div>
						</div>
					</div>
                </div>
            </div>


            <MDBModal show={showFotoEditModal} setShow={setShowFotoEditModal} tabIndex='-1'>
                <MDBModalDialog size='fullscreen'>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle><h4>Editar Imagem do perfil</h4> </MDBModalTitle>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div className="row">
                                <div className="col col-sm-8">
                                    <div className="box">
                                    <Cropper
                                        style={{ height: '450px', width: '100%' }}
                                        zoomTo={1}
                                        initialAspectRatio={1/1}
                                        aspectRatio={1/1}
                                        preview=".img-preview"
                                        src={image}
                                        scaleX={1}
                                        scaleY={1}
                                        viewMode={1}
                                        minCropBoxHeight={10}
                                        minCropBoxWidth={10}
                                        background={false}
                                        responsive={true}
                                        autoCropArea={1}
                                        checkOrientation={false}
                                        onInitialized={(instance) => {
                                            setCropper(instance);
                                        }}
                                        guides={true}
                                        />
                                    </div>
                                </div>
                                <div className="col col-sm-4">
                                    
                                    <div className="box" style={{ width: "250px", height: "250px" }}>
                                        <div id="foto" className="img-preview" style={{ width: "250px", height: "250px"}} />
                                        <canvas id="canvas" style={{ width: "250px", height: "250px"}} width="250" height="250"></canvas>
                                    </div>
                                    <br />

                                    <button className="btn btn-primary btn-height btn-rounded mb-2" type="button" onClick={() => getCropData(selectedImage.foto)} >Salvar Recorte <small>(250x250)</small></button>
                                    <input type="file" ref={hiddenFileInput} onChange={onChange} style={{display: 'none'}} accept=".jpg,.png"/>
                                    <br />
                                    <button className="btn btn-info btn-height btn-rounded mb-2" type="button" onClick={_handlerUploadClick} >Enviar nova foto</button>
                                </div>
                            </div>
                        </MDBModalBody>

                        <MDBModalFooter>
                            <button className="btn btn-primary btn-height btn-rounded mb-2" type="button" onClick={_handleCloseFotoEditModal}>Fechar</button>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    )
}