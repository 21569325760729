/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext'
import { InvalidatorContext } from '../../../Context/InvalidatorContext';
import { Request } from '../../../scripts/Request';
import { HubObjects } from '../../../scripts/HubObjects';
import { WaitObject } from '../../../scripts/Wait';
import { Currency } from '../../../scripts/StringUtils';
import { TimeAgo } from '../../../scripts/DateTimeFilter';
import { Cache} from '../../../scripts/Cache';
import { requestForToken } from '../../../firebaseInit';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment/moment';

import ReactGA from 'react-ga';

import './index.css';



export default function Home() {
    const component_name = "admin/home";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Invalidator, Invalidate, InvalidateStream } = useContext(InvalidatorContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [statuslist, setStatusList] = useState(["Inicial","Baixo","Normal","Alto","Desejado"]);
    const [info, setInfo] = useState([]);
    const [notifyble, setNotifyable] = useState(false);
    const [isTokenFound, setTokenFound] = useState(false);
    const [memory, setMemory] = useState({});
    const [moreContent, setMoreContent] = useState("");

    const [uinfo, setUinfo] = useState({
		nome: "",
		iam: ""
	});

    const data_modalidade = useRef([
        { id: 1, name: "Investidor anjo" },
        { id: 2, name: "Venture capital" },
        { id: 3, name: "Seed money" },
        { id: 4, name: "Smart money" },
        { id: 9, name: "Outro" }
      ]);

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    //const socketRef = useRef(null);

    
    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                    //InitWebsocket();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(loading || pageLoading) {
            window.$('#preloader').fadeIn(500);
        } else {
            window.$('#preloader').fadeOut(500);
        }
     }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = () => {
        HubObjects.ExecAsync("HEADER_TITLE", "Dashboard");

        let _nome = "";
		let _arr_name = user.nome.split(' ');
		if(_arr_name.length > 1) {
			_nome = _arr_name[0] + " " + _arr_name[1];
		} else {
			_nome = _arr_name[0];
		}

        let _iam = "Não definido";

        setMoreContent("/Projetos");

		if(user.origin < 3) {
			switch(user.iam) { 
				case 1:
					_iam = "Empresa"
					break;
				case 2:
					_iam = "Startup"
					break;
				case 3:
					_iam = "Instituição"
					break;
				case 4:
					_iam = "Empreendedor"
					break;
				case 9:
					_iam = "Não definido"
					break;
			}

            if(user.origin === 2) {
                setMoreContent("/Professionais");
            }
        } else if(user.origin === 3) {
            _iam = "Profissional"
           
        } else if(user.origin === 4) {
            _iam = "Investidor";
        }
        


		setUinfo(prev => ({
			...prev,
			nome: _nome,
			iam: _iam
		}));

        //LoadCategorias();
        LoadInfo();
        NotifyMe();
    }
    //#endregion Init


    //#region Websocket
    /*const InitWebsocket = (host = "127.0.0.1") => {
        socketRef.current = new WebSocket("ws:"+host+":9001/");
        socketRef.current.onopen = () => { 
            console.log(host + " success"); 
            socketRef.current.send("");
        };
        socketRef.current.onmessage = (msg)  => { 
            console.log(msg) 
        };
        socketRef.current.onclose = () => { 
            console.log(host + " closed"); 
        };
    }*/
    //#endregion Websocket


    //#region Loaders
    const LoadCategorias = () => {
        Promise.resolve(new Request().Run("api/Auth/Categorias", "GET", "", "", 1*24*60*60*1000))
        .then((data) => {
            let men = Object.assign({}, memory);
            if(men.categorias)
            {
                men.categorias = data;
            } else {
                men = Object.assign(men, {categorias: data})
            }
            setMemory(men);
        }).catch(() => {
            
        });
    }

    const LoadInfo = (force = false) => {
        setPageLoading(true);
        Promise.resolve(new Request().Run("api/Home/Load", "POST", {force: force}, user.token, 0*300*1000)) ///json/info.json"
        .then((data) => {
            setPageLoading(false);
            if(data.toString() !== "error") {
                setInfo(data);

                setTimeout(() => {
                    window.carouselReview();
                    window.dzChartlist.load(data[10]);
                }, 100);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            setPageLoading(false);
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }
    //#endregion Loaders


    //#region Handlers
	const HandlerImageError = (e) => {
        e.target.src = "images/db/0.jpg";
	}


    const NotifyMe = async(event) => {
        let permission = await Notification.permission;
        //console.log(permission);

        if(permission === "denied") return;
        if(permission === "granted") {
            NotificationsPermissions();
            return;
        }

        let result = Cache.JGet('notification_permission');

        let isNotify = -1;
        let milliseconds = 0;
        let hasConfig = true;
        let json;

        if(result.length > 0) {
            json = JSON.parse(result[0].value);

            milliseconds = parseInt(json.milliseconds);
            //console.log(new Date().getTime(), milliseconds, new Date().getTime() - milliseconds, ((new Date().getTime() - milliseconds) > (24*60*60*1000)));

            if((new Date().getTime() - milliseconds) > (24*60*60*1000))
            {
                console.log(json.data);
                isNotify = parseInt(json.data);
            }
        } else {
            isNotify = 0;
            hasConfig = false;
        }


        if(isNotify === 0) {
            window.swal({
                title: "Notificações",
                text: "Deseja receber notificações sobre os Matches diretamente no navegador?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Sim',
                cancelButtonText: "Não",
                closeOnConfirm: true,
                closeOnCancel: true
                },
                function (isConfirm)
                {
                if (isConfirm){
                    /*if(!hasConfig) {
                        db.Query("INSERT INTO config (name, value) VALUES (?,?)", ['notification_permission', JSON.stringify({data: 1, milliseconds: new Date().getTime()})]);
                    
                    } else {
                        db.Query("UPDATE config SET value=? WHERE name=?", [JSON.stringify({data: 1, milliseconds: new Date().getTime()}), 'notification_permission']);
                    }*/
                    Cache.JSet('notification_permission', {data: 1, milliseconds: new Date().getTime()});

                    
                    NotificationsPermissions();
                } else {
                    /*if(!hasConfig) {
                        console.log(1);
                        db.Query("INSERT INTO config (name, value) VALUES (?,?)", ['notification_permission', JSON.stringify({data: 0, milliseconds: new Date().getTime()})]);
                    } else {
                        console.log(2);
                        db.Query("UPDATE config SET value=? WHERE name=?", [JSON.stringify({data: 0, milliseconds: new Date().getTime()}), 'notification_permission']);
                    }*/
                    Cache.JSet('notification_permission', {data: 0, milliseconds: new Date().getTime()});
                }
            });
        } else if(isNotify === 1) {
            NotificationsPermissions();
        }
    
        
    }


    const NotificationsPermissions = async() => {
        let data = await requestForToken(setTokenFound);
        if (data) {
            //console.log("Token is", data);
            Cache.Set("firebase_token", data);

            Promise.resolve(new Request().Run("api/Home/Token", "POST", {user_id: user.user_id, token: data}, user.token))
            .then((data) => {
                if(data.toString() !== "error") {
                
                } else {
                    console.log("Record Token error");
                }
            }).catch(() => {
                console.log("Record Token error");
            });

            setNotifyable(true);
        } else {
            setNotifyable(false);
        }
    }
	//#endregion Handlers


    //#region Functions
    const GetKind = (index) => {
        if(user.origin === 0) {
            return Tempo(index);
        } else if(user.origin === 2) {
            return "Match " + index + "%";
        } else {
            switch(parseInt(index)) {
                case 1:
                    return "Fase de conceito";
                case 2:
                    return "Desenvolvimento";
                case 3:
                    return "Concluído";
                default:
                    return "Entrega não definida";
            }
        }
    }


    const Tempo = (m) => {
        switch(parseInt(m)) {
            case 0:
                return "Imediato";
            case 1:
                return "Até 1 mês";
            case 2:
                return "Até 2 meses";
            case 3:
                return "Até 3 meses";
            case 6:
                return "Até 6 meses";
            case 12:
                return "Até 12 meses";
            case 24:
                return "Até 24 meses";
            case 98:
                return "Mais que 24 meses";
            case 99:
                return "Indefinido";
            default:
                return "Indefinido";
        }
    }


    const GetModalidade = (index) => {
        try {
            return data_modalidade.current.find(v => v.id === index).name;
        } catch(e) {
            return "Não preenchido";
        }
    }


    const Info = (obj, index) => {
        if(obj && obj.length) {
            if(obj[index]) {
                //console.log(obj[index].name, obj[index].svalue, obj[index].dvalue);
                if(obj[index].svalue !== undefined && obj[index].svalue !== "" && obj[index].svalue !== null) {
                    //console.log("a", obj[index].svalue, obj[index].dvalue);
                    return obj[index].svalue;
                } else if(obj[index].dvalue !== undefined && obj[index].dvalue !== "" && obj[index].dvalue !== null) {
                    //console.log("b");
                    return obj[index].dvalue;
                }
            }
        }
        return "";
    }
    //#endregion  Functions


    return (
        <div className="Home content-body">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-3 col-xxl-6 col-sm-6">
                        <div className="card bg-primary">
                            <div className="card-body">
                                <div className="media align-items-center">
                                    <span className="p-3 mr-3 feature-icon rounded">
                                        <i className="la la-glass-cheers la-2x"></i>
                                    </span>
                                    <div className="media-body text-right">
                                        <p className="fs-18 text-white mb-2">{info.length > 0 && info[0] !== null && info[0].name}</p>
                                        <span className="fs-48 text-white font-w600">{info.length > 0 && info[0] !== null && parseInt(Info(info, 0))}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-xxl-6 col-sm-6">
                        <div className="card bg-info">
                            <div className="card-body">
                                <div className="media align-items-center">
                                    <span className="p-3 mr-3 feature-icon rounded ">
                                        <i className="la la-donate la-2x"></i>
                                    </span>
                                    <div className="media-body text-right fs-fix">
                                        <p className="fs-18 text-white mb-2">{info.length > 0 && info[1] !== null && info[1].name}</p>
                                        <span className="fs-24 text-white font-w500 mb-8">{info.length > 0 && info[1] !== null && (Info(info, 1))}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-xxl-6 col-sm-6">
                        <div className="card bg-success">
                            <div className="card-body">
                                <div className="media align-items-center">
                                    <span className="p-3 mr-3 feature-icon rounded">
                                        <i className="la la-fire la-2x"></i>
                                    </span>
                                    <div className="media-body text-right fs-fix">
                                        <p className="fs-18 text-white mb-2">{info.length > 0 && info[2] !== null && info[2].name}</p>
                                        <span className="fs-35 text-white font-w500 mb-8">{info.length > 0 && info[2] !== null && (statuslist[parseInt(Info(info, 2))])}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-xxl-6 col-sm-6">
                        <div className="card bg-secondary">
                            <div className="card-body">
                                <div className="media align-items-center">
                                    <span className="p-3 mr-3 feature-icon rounded">
                                        <i className="la fab la-modx la-2x"></i>
                                    </span>
                                    <div className="media-body text-right">
                                        <p className="fs-18 text-white mb-2">{info.length > 0 && info[3] !== null && info[3].name}</p>
                                        <span className="fs-48 text-white font-w600">{(Info(info, 3))}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-xl-3 col-xxl-4">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card d-flex flex-xl-column flex-sm-column flex-md-row flex-column">
                                    <div className="card-body text-center profile-bx">
                                        <div className="profile-image mb-4">
                                            <img id="profile_main_pic" src={'images/db/' + user.user_id + '.jpg?' + (Invalidator['profile_image'])} className="rounded-circle" alt="" onError={HandlerImageError}/>
                                        </div>
                                        <h4 className="fs-22 text-black mb-1">{uinfo.nome}</h4>
                                        <p className="mb-4">{uinfo.iam}</p>

                                        { 
                                            user.origin === 0 && (
                                                <div className="row">
                                                    <div className="col-4 p-0">
                                                        <div className="d-inline-block mb-2 relative donut-chart-sale">
                                                            <span className="donut" data-peity='{ "fill": ["rgb(255, 142, 38)", "rgba(236, 236, 236, 1)"],   "innerRadius": 27, "radius": 10}'>{Info(info, 4)}/100</span>
                                                            <small className="text-black">{Info(info, 4).toString().split("/")[0]}%</small>
                                                        </div>
                                                        <span className="d-block">{info.length > 0 && info[4] !== null && info[4].name}</span>
                                                    </div>
                                                    <div className="col-4 p-0">
                                                        <div className="d-inline-block mb-2 relative donut-chart-sale">
                                                            <span className="donut" data-peity='{ "fill": ["rgb(62, 168, 52)", "rgba(236, 236, 236, 1)"],   "innerRadius": 27, "radius": 10}'>{Info(info, 5)}/100</span>
                                                            <small className="text-black">{Info(info, 5)}%</small>
                                                        </div>
                                                        <span className="d-block">{info.length > 0 && info[5] !== null && info[5].name}</span>
                                                    </div>
                                                    <div className="col-4 p-0">
                                                        <div className="d-inline-block mb-2 relative donut-chart-sale">
                                                            <span className="donut" data-peity='{ "fill": ["rgb(34, 172, 147)", "rgba(236, 236, 236, 1)"],   "innerRadius": 27, "radius": 10}'>{Info(info, 6)}/100</span>
                                                            <small className="text-black">{Info(info, 6)}%</small>
                                                        </div>
                                                        <span className="d-block">{info.length > 0 && info[6] !== null && info[6].name}</span>
                                                    </div>
                                                </div>
                                            )
                                         }



                                        {
                                            user.origin === 1 && (
                                                <div className="UserInfo">
                                                    <div className="d-flex mb-3 rounded" style={{backgroundColor:'#fbfbfb'}}>
                                                        <a className="contact-icon mr-3" style={{backgroundColor:'#3D6AD6', color:'#FFFFFF'}}><i className="fa fa-calendar"></i></a>
                                                        <span className="font-w500">Período de contrato<br /><small>{(Info(info, 6))}</small></span>
                                                    </div>
                                                    <div className="d-flex mb-3 rounded" style={{backgroundColor:'#fbfbfb'}}>
                                                        <a className="contact-icon mr-3" style={{backgroundColor:'#0073B1', color:'#FFFFFF'}}><i className="fa fa-pie-chart"></i></a>
                                                        <span className="font-w500">Projeção Mensal<br /><small>{Info(info, 5)}</small></span>
                                                    </div>
                                                    <div className="d-flex mb-3 rounded" style={{backgroundColor:'#fbfbfb'}}>
                                                        <a className="contact-icon mr-3" style={{backgroundColor:'#EA518D', color:'#FFFFFF'}}><i className="fa fa-street-view"></i></a>
                                                        <span className="font-w500">Avaliação<br /><small>{Info(info, 4)}%</small></span>
                                                    </div>
                                                </div>
                                            )

                                        }


                                        { 
                                            user.origin === 2 && (
                                                <div className="row">
                                                    <div className="col-4 p-0">
                                                        <div className="d-inline-block mb-2 relative donut-chart-sale">
                                                            <span className="donut" data-peity='{ "fill": ["rgb(255, 142, 38)", "rgba(236, 236, 236, 1)"],   "innerRadius": 27, "radius": 10}'>{Info(info, 4)}/100</span>
                                                            <small className="text-black">{Info(info, 4).toString().split("/")[0]}%</small>
                                                        </div>
                                                        <span className="d-block">{info.length > 0 && info[4] !== null && info[4].name}</span>
                                                    </div>
                                                    <div className="col-4 p-0">
                                                        <div className="d-inline-block mb-2 relative donut-chart-sale">
                                                            <span className="donut" data-peity='{ "fill": ["rgb(62, 168, 52)", "rgba(236, 236, 236, 1)"],   "innerRadius": 27, "radius": 10}'>{Info(info, 5)}/100</span>
                                                            <small className="text-black">{Info(info, 5)}%</small>
                                                        </div>
                                                        <span className="d-block">{info.length > 0 && info[5] !== null && info[5].name}</span>
                                                    </div>
                                                    <div className="col-4 p-0">
                                                        <div className="d-inline-block mb-2 relative donut-chart-sale">
                                                            <span className="donut" data-peity='{ "fill": ["rgb(34, 172, 147)", "rgba(236, 236, 236, 1)"],   "innerRadius": 27, "radius": 10}'>{Info(info, 6)}/100</span>
                                                            <small className="text-black">{Info(info, 6)}%</small>
                                                        </div>
                                                        <span className="d-block">{info.length > 0 && info[6] !== null && info[6].name}</span>
                                                    </div>
                                                </div>
                                            )
                                        }


                                        { 
                                            user.origin === 3 && (
                                                <div className="row">
                                                    <div className="col-4 p-0">
                                                        <div className="d-inline-block mb-2 relative donut-chart-sale">
                                                            <span className="donut" data-peity='{ "fill": ["rgb(255, 142, 38)", "rgba(236, 236, 236, 1)"],   "innerRadius": 27, "radius": 10}'>{Info(info, 4)}/100</span>
                                                            <small className="text-black">{Info(info, 4).toString().split("/")[0]}%</small>
                                                        </div>
                                                        <span className="d-block">{info.length > 0 && info[4] !== null && info[4].name}</span>
                                                    </div>
                                                    <div className="col-4 p-0">
                                                        <div className="d-inline-block mb-2 relative donut-chart-sale">
                                                            <span className="donut" data-peity='{ "fill": ["rgb(62, 168, 52)", "rgba(236, 236, 236, 1)"],   "innerRadius": 27, "radius": 10}'>{Info(info, 5)}/100</span>
                                                            <small className="text-black">{Info(info, 5)}%</small>
                                                        </div>
                                                        <span className="d-block">{info.length > 0 && info[5] !== null && info[5].name}</span>
                                                    </div>
                                                    <div className="col-4 p-0">
                                                        <div className="d-inline-block mb-2 relative donut-chart-sale">
                                                            <span className="donut" data-peity='{ "fill": ["rgb(34, 172, 147)", "rgba(236, 236, 236, 1)"],   "innerRadius": 27, "radius": 10}'>{Info(info, 6)}/100</span>
                                                            <small className="text-black">{Info(info, 6)}%</small>
                                                        </div>
                                                        <span className="d-block">{info.length > 0 && info[6] !== null && info[6].name}</span>
                                                    </div>
                                                </div>
                                            )
                                        }

                                        
                                        {
                                            user.origin === 4 && (
                                                <div className="UserInfo">
                                                    <div className="d-flex mb-3 rounded" style={{backgroundColor:'#fbfbfb'}}>
                                                        <a className="contact-icon mr-3" style={{backgroundColor:'#3D6AD6', color:'#FFFFFF'}}><i className="fa fa-calendar"></i></a>
                                                        <span className="font-w500">Porcentagem<br /><small>{Info(info, 6)}</small></span>
                                                    </div>
                                                    <div className="d-flex mb-3 rounded" style={{backgroundColor:'#fbfbfb'}}>
                                                        <a className="contact-icon mr-3" style={{backgroundColor:'#0073B1', color:'#FFFFFF'}}><i className="fa fa-pie-chart"></i></a>
                                                        <span className="font-w500">Modalidade<br /><small>{GetModalidade(Info(info, 5))}</small></span>
                                                    </div>
                                                    <div className="d-flex mb-3 rounded" style={{backgroundColor:'#fbfbfb'}}>
                                                        <a className="contact-icon mr-3" style={{backgroundColor:'#EA518D', color:'#FFFFFF'}}><i className="fa fa-street-view"></i></a>
                                                        <span className="font-w500">Avaliação<br /><small>{Info(info, 4)}%</small></span>
                                                    </div>
                                                </div>
                                            )

                                        }



                                    </div>
                                    <div className="card-body col-xl-12 col-md-6 col-sm-12 activity-card">
                                        <h4 className="fs-18 text-black mb-3">Tarefas em espera</h4>
                                        {
                                            Array.isArray(info) && info.length > 0 && info[7].value.length > 0 && info[7] !== null && info[7].value.map((item, i) => (
                                                <LinkContainer to={item.local} key={'tasks'+i}>
                                                    <div key={'task'+i} className="media mb-4 pointer">
                                                        <span className={'p-3 border mr-3 rounded' + (item.status===0?' ':' ok')}>
                                                        <i className={'la ' + (item.status===0?'la-history':'la-check')}></i>
                                                        </span>
                                                        <div className="media-body">
                                                            <p className="fs-14 mb-1 text-black font-w500">{item.content}</p>
                                                            <span className="fs-14">{TimeAgo(item.date)}</span>
                                                        </div>
                                                    </div>
                                                </LinkContainer>
                                            ))
                                        }

                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-9 col-xxl-8">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-header border-0 pb-0 flex-wrap">
                                        <h4 className="fs-20 text-black mr-4 mb-2">Evolução de métricas</h4>
                                        
                                    </div>
                                    <div className="card-body">	
                                        <canvas id="lineChart" className="Vacancy-chart"></canvas>
                                        <div className="d-flex flex-wrap align-items-center justify-content-center mt-3">
                                            {
                                                Array.isArray(info) && info.length > 0 && info[10] !== null && info[10].value.length > 0 && info[10].value.map((item, i) => (
                                                    <div key={'key'+i} className="fs-14 text-black mr-4">
                                                        <svg className="mr-2" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect width="19" height="19" rx="9.5" fill={item.color}/>
                                                        </svg>
                                                        {item.label}
                                                    </div>
                                                ))
                                            }

                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12">
                                <h4 className="fs-20 text-black mb-sm-4 mt-sm-0 mt-2  mb-2">Matches Recentes</h4>
                                <div className="testimonial-one owl-carousel">
                                    {
                                        Array.isArray(info) && info.length > 0 && info[8] !== null && info[8].value.length > 0 && info[8].value.map((item, i) => (
                                            <div className="items pointer" key={'key'+i} >
                                                <div className="card shadow">
                                                    <div className="card-body">	
                                                        <div className="media mb-2">
                                                            <div className="media-body">
                                                                <p className="mb-1">{item.label}</p>
                                                                <h4 className="fs-20 text-black">{item.hunt}</h4>
                                                            </div>
                                                            <img src={'images/categorias/' + item.img} style={{width:'70px', height:'70px', clipPath: 'circle(48px at center)'}} alt={item.hunt} title={item.hunt}/>
                                                        </div>
                                                        <span className="text-primary font-w500 d-block mb-3">{item.options}</span>
                                                        <p className="fs-14 desc-content">{item.content.trim().replace(/[\r\n]/gm, ' ')}</p>
                                                        <div className="d-flex align-items-center mt-4">
                                                            <a className="btn btn-primary light btn-rounded mr-auto">{GetKind(item.kind)}</a>
                                                            <span className="text-black font-w500 pl-3">{item.local}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }


                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="d-sm-flex align-items-center mb-3 mt-sm-0 mt-2">
                            <h4 className="text-black fs-20 mr-auto">Editais e chamadas públicas</h4>
                            <LinkContainer to={moreContent}>
                            <a className="btn btn-primary light btn-rounded">Ver Mais
                                <svg className="ml-3" width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.5607 5.93941L18.2461 0.62482C17.9532 0.331898 17.5693 0.185461 17.1854 0.185461C16.8015 0.185461 16.4176 0.331898 16.1247 0.62482C15.539 1.21062 15.539 2.16035 16.1247 2.74615L18.8787 5.50005L1.5 5.50005C0.671578 5.50005 0 6.17163 0 7.00005C0 7.82848 0.671578 8.50005 1.5 8.50005L18.8787 8.50005L16.1247 11.254C15.539 11.8398 15.539 12.7895 16.1247 13.3753C16.7106 13.9611 17.6602 13.9611 18.2461 13.3753L23.5607 8.06069C24.1464 7.47495 24.1464 6.52516 23.5607 5.93941Z" fill="#40189D"/>
                                </svg>
                            </a>
                            </LinkContainer>
                        </div>
                        <div className="testimonial-two owl-carousel">
                            {
                                info.length > 0 && info[9] !== null && info[9].value.length > 0 && info[9].value.map((item, i) => (
                                    <LinkContainer to={'/Editais/'+item.id}>
                                    <a>
                                        <div className="items pointer" key={'edital'+i}>
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="media">

                                                        <img src={'/images/categorias/' + item.img}style={{width:'80px', height:'80px', clipPath: 'circle(54px at center)'}} alt={item.alt} title={item.alt}/>
                                                        
                                                        <div className="media-body" style={{marginLeft:'10px'}}>
                                                            <h6 className="fs-16 text-black font-w400 edital-content "><b>{item.label}</b></h6>
                                                            <span className="text-primary font-w500">{item.content}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    </LinkContainer>
                                ))
                            }


                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}