/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext'
import { InvalidatorContext } from '../../../Context/InvalidatorContext';
import { Request } from '../../../scripts/Request';
import { Sleep, Wait, WaitObject } from '../../../scripts/Wait';
import { HubObjects } from '../../../scripts/HubObjects';
import { Currency } from '../../../scripts/StringUtils';
import { useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment';
import ReactGA from 'react-ga';
import './index.css';





export default function Busca() {
    const component_name = "admin/busca";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
	const { Invalidator, Invalidate, InvalidateStream } = useContext(InvalidatorContext);
    const [pageLoading, setPageLoading] = useState(false);
	const [wordList, setWordList] = useState([]);
    const [list, setList] = useState([]);
    const [search, setSearch] = useState({ word: "", tipo: 0, recentes: 1, origin: 0, km: "", categoria: "", expiradas: 0, force: false });
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(0);
	const [range, setRange] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const listRef = useRef();
    const pageRef = useRef();
    const totalRef = useRef();
    const loadPrestineRef = useRef(false);
    const searchRef = useRef({ word: "", tipo: 0, recentes: 1,  origin: 0, km: "", categoria: "", expiradas: 0, force: false });
    const timerIdleRef = useRef(new Date());


    let { Id } = useParams();


	const data_modalidade = useRef([
        { id: 1, name: "Investidor anjo" },
        { id: 2, name: "Venture capital" },
        { id: 3, name: "Seed money" },
        { id: 4, name: "Smart money" },
        { id: 9, name: "Outro" }
      ]);

    
    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        WaitObject(user.nome).then(() => {
            Init();
        });

        return () => {
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(loading || pageLoading){
         window.$('#preloader').fadeIn(500);
        } else {
         window.$('#preloader').fadeOut(500);
        }
     }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = () => {
        if(!loadPrestineRef.current) {
            loadPrestineRef.current = true;

            HubObjects.Exec("HEADER_TITLE", "Busca agregada");
			if(Id === "" || Id === undefined || Id === null) Id = 1;
			LoadMatches(Id);
			LoadKeywords();
        }
    }


    //#endregion Init


	//#region Loaders
	const LoadMatches = (page, searchObj = null) => {
		if(searchObj === null) {
			let _search = {...search};
			searchRef.current = _search
			pageRef.current = page;
		} else {
			pageRef.current = searchObj;
		}

	
		setPageLoading(true);
        Promise.resolve(new Request().Run("api/Busca/List?page=" + page + "&interval=9", "POST", searchRef.current, user.token, 0*300*1000)) 
        .then((data) => {
			setPage(page);
            setPageLoading(false);
			window.scrollTo({top: 0, behavior: 'smooth'});
            if(data.toString() !== "error") {
				let fim = Math.ceil(data.total / 9);
				let _copy_fim = fim;

				if(fim > 5) fim = 5;
				let arr = [];
				for(let i = 1; i <= fim; i++ ) {
					if((i - 3 + page) < _copy_fim) arr.push(i);
				}

				setRange(arr);
				
				totalRef.current = data.total;
				setTotal(totalRef.current);
				setList(data.list);

				setTimeout(() => {
                    window.carouselReview();
                }, 100);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            setPageLoading(false);
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
	}


	const LoadKeywords = () => {
		Promise.resolve(new Request().Run("api/Busca/Keywords", "POST", {force: false}, user.token, 0*300*1000)) 
        .then((data) => {
            setPageLoading(false);
            if(data.toString() !== "error") {
				setWordList(data);
            }
        }).catch(() => {
            
        });
	}
	//#endregion Loaders


	//#region Handlers
	const _HandleChange = (e) => {
		if(e.target.name === "all") {
			searchRef.current = { ...search, tipo: parseInt(e.target.value)};
			setSearch(searchRef.current);
		} else if(e.target.id === "expiradas") {
			searchRef.current = { ...search, [e.target.id]: (e.target.checked ? 1 : 0)};
			setSearch(searchRef.current);
			LoadMatches(1, searchRef.current);
		} else {
			searchRef.current = { ...search, [e.target.id]: e.target.value};
			setSearch(searchRef.current);
			if(e.target.id === "recentes" ||e.target.id === "origin") {
				LoadMatches(1, searchRef.current);
			}
		}
	}


	const _HandleClear = () => {
		searchRef.current = { word: "", tipo: 0, recentes: 1,  origin: 0, km: "", categoria: "", expiradas: 0, force: false };
		setSearch(searchRef.current);
		LoadMatches(1, searchRef.current);
	}


	const _HandleKeywordClick = (cat_id) => {
		searchRef.current = { ...search, categoria: cat_id};
		setSearch(searchRef.current);
		LoadMatches(1, searchRef.current);
	}


	const HandlerImageError = (e) => {
        e.target.src = "images/db/0.jpg";
	}
	//#endregion Handlers


	//#region Functions
	const GetKind = (index) => {
		let _search = {...search};
        if(parseInt(_search.origin) === 0) {
            return Tempo(index);
		} else if(parseInt(_search.origin) === 1) {
			return "Avaliação " + index + "%";
        } else if(parseInt(_search.origin) === 2 || parseInt(_search.origin) === 3) {
			return "Habilidade " + index + "%";
		} else if(parseInt(_search.origin) === 4) {
			return GetModalidade(index);
		} else if(parseInt(_search.origin) === 5) {
			if(index && index !== "" && index.toString().indexOf("|") > -1) {
				return moment(index.indexOf("|")[1]).format('DD/MM/YYYY');
			} else {
				return "Prazo indefinido"
			}

			
        } else {
            switch(parseInt(index)) {
                case 1:
                    return "Fase de conceito";
                case 2:
                    return "Desenvolvimento";
                case 3:
                    return "Concluído";
                default:
                    return "Entrega não definida";
            }
        }
    }


	const GetFinanciamento = (arr) => {
		let _search = {...search};
		if(parseInt(_search.origin) === 5) {
			if(arr && arr !== "" && arr.toString().indexOf("|") > -1) {
				return arr[2];
			} else {
				return "Prazo indefinido"
			}
        } 
	}


    const Tempo = (m) => {
        switch(parseInt(m)) {
            case 0:
                return "Imediato";
            case 1:
                return "Até 1 mês";
            case 2:
                return "Até 2 meses";
            case 3:
                return "Até 3 meses";
            case 6:
                return "Até 6 meses";
            case 12:
                return "Até 12 meses";
            case 24:
                return "Até 24 meses";
            case 98:
                return "Mais que 24 meses";
            case 99:
                return "Indefinido";
            default:
                return "Indefinido";
        }
    }


    const GetModalidade = (index) => {
        try {
            return data_modalidade.current.find(v => v.id === index).name;
        } catch(e) {
            return "Não preenchido";
        }
    }


    const Info = (obj, index) => {
        if(obj && obj.length) {
            if(obj[index]) {
                //console.log(obj[index].name, obj[index].svalue, obj[index].dvalue);
                if(obj[index].svalue !== undefined && obj[index].svalue !== "" && obj[index].svalue !== null) {
                    //console.log("a", obj[index].svalue, obj[index].dvalue);
                    return obj[index].svalue;
                } else if(obj[index].dvalue !== undefined && obj[index].dvalue !== "" && obj[index].dvalue !== null) {
                    //console.log("b");
                    return obj[index].dvalue;
                }
            }
        }
        return "";
    }
    //#endregion  Functions


    return (
        <div className="content-body Projetos">
            <div className="container-fluid">
                <div className="d-flex align-items-center flex-wrap search-job bg-white rounded py-3 px-md-3 px-0 mb-4">
					<div className="col-lg-3 search-dropdown d-flex align-items-center">
						{/*<svg className="min-w20 primary-icon" width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M10 0C4.93398 0 0.8125 4.12148 0.8125 9.1875C0.8125 10.8091 1.24094 12.4034 2.05145 13.7979C2.24041 14.123 2.45162 14.4398 2.67934 14.7396L9.60081 24H10.3991L17.3207 14.7397C17.5483 14.4398 17.7595 14.1231 17.9485 13.7979C18.7591 12.4034 19.1875 10.8091 19.1875 9.1875C19.1875 4.12148 15.066 0 10 0ZM10 12.2344C8.31995 12.2344 6.95312 10.8675 6.95312 9.1875C6.95312 7.50745 8.31995 6.14062 10 6.14062C11.68 6.14062 13.0469 7.50745 13.0469 9.1875C13.0469 10.8675 11.68 12.2344 10 12.2344Z" fill="#40189D"/>
						</svg>*/}
						<select id="km" className={'form-control border-no default-select style-1'} disabled={(parseInt(search.origin) === 5 ? ' disabled':'')}>
							<option value="">Todos lugares</option>
							<option value="25000">até 25 km</option>
							<option value="50000">até 50 km</option>
							<option value="80000">até 80 km</option>
							<option value="150000">até 150 km</option>
						</select>
					</div>
					<div className="col-lg-9 d-md-flex">
						<input id="word" className="form-control input-rounded mr-auto mb-md-0 mb-3" value={search.word} type="text" placeholder="buscar por descrição" onChange={_HandleChange}/>
						<a className="bg-light btn btn-rounded text-primary mr-3" onClick={_HandleClear}><i className="las la-trash scale5 mr-3"></i>LIMPAR</a>
						<a className="btn btn-primary btn-rounded" onClick={()=> LoadMatches(1)}><i className="las la-search scale5 mr-3"></i>BUSCAR</a>
					</div>
				</div>
				<div className="d-flex pb-3 mb-4 border-bottom flex-wrap align-items-center">
					{
						wordList && wordList.length > 0 && wordList.map((item, i) => (
							<a key={'workey'+i} className={'btn btn-primary btn-rounded mr-2 mb-2 pointer ' + (search.categoria===item.id?'':'light')} onClick={() => _HandleKeywordClick(item.id)}>{item.name}</a>
						))
					}

					{/* 
					<a href="javascript:void(0);" className="btn btn-primary light btn-rounded mr-2 mb-2">Suggestions</a>
					<a href="javascript:void(0);" className="btn btn-primary light btn-rounded mr-2 mb-2">Your Skill</a>
					<a href="javascript:void(0);" className="btn btn-primary btn-rounded mr-2 mb-2">Programmer</a>
					<a href="javascript:void(0);" className="btn btn-primary light btn-rounded mr-2 mb-2">Software Engineer</a>
					<a href="javascript:void(0);" className="btn btn-primary light btn-rounded mr-2 mb-2">Photographer</a>
					<a href="javascript:void(0);" className="btn btn-primary light btn-rounded mr-2 mb-2">Digital Marketing</a>
					*/}
				</div>

				<div className="d-flex flex-wrap mb-4 align-items-center search-filter">
					<div className="mr-auto mb-2 pr-2">
						<h6 className="text-black fs-16 font-w600 mb-1">{total} resultados encontrados</h6>
						{/* span className="fs-14">Based your preferences</span> */}
					</div>
					{/* 
					<div className="custom-control check-switch custom-checkbox mr-4 mb-2">
						<input type="radio" className="custom-control-input" id="customCheck9" name="all" value={0} checked={search.tipo===0} onChange={_HandleChange} />
						<label className="custom-control-label" for="customCheck9">Matches</label>
					</div>
					<div className="custom-control check-switch custom-checkbox mr-5 mb-2">
						<input type="radio" className="custom-control-input" id="customCheck1" name="all" value={1} checked={search.tipo===1}  onChange={_HandleChange}/>
						<label className="custom-control-label" for="customCheck1">Todos resultados</label>
					</div>
					
					<div className="custom-control custom-switch toggle-switch text-right mr-4 mb-2">
						<input type="checkbox" className="custom-control-input" id="relevantes" checked={search.relevantes===1} onClick={_HandleChange}/>
						<label className="custom-control-label" for="relevantes">Mais relevantes</label>
					</div>*/}
					
					<div className={'custom-control custom-switch toggle-switch text-right mr-5 mb-2' + (parseInt(search.origin) === 5 ? ' hide':'')}>
						<input type="checkbox" className="custom-control-input" id="expiradas"  checked={search.expiradas===1} onClick={_HandleChange}/>
						<label className="custom-control-label" for="expiradas">Expiradas</label>
					</div>

					<select id="origin" className="form-control default-select style-1 mr-3 border-primary" value={search.origin} onChange={_HandleChange}>
						<option value="0">Empresas com solução</option>
						<option value="1">Empresas procurando solução</option>
						<option value="2">Profissionais</option>
						<option value="3">Empresas buscando profissional</option>
						<option value="4">Investidores</option>
						<option value="5">Editais</option>
					</select>
					
					<select id="recentes" className="form-control default-select style-1 mr-3 border-primary" value={search.recentes} onChange={_HandleChange}>
						<option value="1">Mais recente</option>
						<option value="0">Mais antigo</option>
					</select>
					<ul className="nav grid-tabs">
						<li className="nav-item">
							<a href="#List" className="nav-link" data-toggle="tab" aria-expanded="false">
								<svg className="scale5 primary-icon" width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M6 7.5H3C2.17275 7.5 1.5 6.82687 1.5 6V3C1.5 2.17313 2.17275 1.5 3 1.5H6C6.82725 1.5 7.5 2.17313 7.5 3V6C7.5 6.82687 6.82725 7.5 6 7.5ZM3 3V6H6.00113L6 3H3ZM22.5 4.5C22.5 4.08544 22.1642 3.75 21.75 3.75H9.75C9.33581 3.75 9 4.08544 9 4.5C9 4.91456 9.33581 5.25 9.75 5.25H21.75C22.1642 5.25 22.5 4.91456 22.5 4.5ZM6 15H3C2.17275 15 1.5 14.3269 1.5 13.5V10.5C1.5 9.67313 2.17275 9 3 9H6C6.82725 9 7.5 9.67313 7.5 10.5V13.5C7.5 14.3269 6.82725 15 6 15ZM3 10.5V13.5H6.00113L6 10.5H3ZM22.5 12C22.5 11.5854 22.1642 11.25 21.75 11.25H9.75C9.33581 11.25 9 11.5854 9 12C9 12.4146 9.33581 12.75 9.75 12.75H21.75C22.1642 12.75 22.5 12.4146 22.5 12ZM6 22.5H3C2.17275 22.5 1.5 21.8269 1.5 21V18C1.5 17.1731 2.17275 16.5 3 16.5H6C6.82725 16.5 7.5 17.1731 7.5 18V21C7.5 21.8269 6.82725 22.5 6 22.5ZM3 18V21H6.00113L6 18H3ZM22.5 19.5C22.5 19.0854 22.1642 18.75 21.75 18.75H9.75C9.33581 18.75 9 19.0854 9 19.5C9 19.9146 9.33581 20.25 9.75 20.25H21.75C22.1642 20.25 22.5 19.9146 22.5 19.5Z" fill="#40189D"/>
								</svg>
							</a>
						</li>
						<li className="nav-item">
							<a href="#Boxed" className="nav-link active" data-toggle="tab" aria-expanded="false">
								<svg className="scale5" width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M10 1H2C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2V10C1 10.2652 1.10536 10.5196 1.29289 10.7071C1.48043 10.8946 1.73478 11 2 11H10C10.2652 11 10.5196 10.8946 10.7071 10.7071C10.8946 10.5196 11 10.2652 11 10V2C11 1.73478 10.8946 1.48043 10.7071 1.29289C10.5196 1.10536 10.2652 1 10 1ZM9 9H3V3H9V9Z" fill="white"/>
									<path d="M22 1H14C13.7348 1 13.4804 1.10536 13.2929 1.29289C13.1054 1.48043 13 1.73478 13 2V10C13 10.2652 13.1054 10.5196 13.2929 10.7071C13.4804 10.8946 13.7348 11 14 11H22C22.2652 11 22.5196 10.8946 22.7071 10.7071C22.8946 10.5196 23 10.2652 23 10V2C23 1.73478 22.8946 1.48043 22.7071 1.29289C22.5196 1.10536 22.2652 1 22 1ZM21 9H15V3H21V9Z" fill="white"/>
									<path d="M10 13H2C1.73478 13 1.48043 13.1054 1.29289 13.2929C1.10536 13.4804 1 13.7348 1 14V22C1 22.2652 1.10536 22.5196 1.29289 22.7071C1.48043 22.8946 1.73478 23 2 23H10C10.2652 23 10.5196 22.8946 10.7071 22.7071C10.8946 22.5196 11 22.2652 11 22V14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13ZM9 21H3V15H9V21Z" fill="white"/>
									<path d="M22 13H14C13.7348 13 13.4804 13.1054 13.2929 13.2929C13.1054 13.4804 13 13.7348 13 14V22C13 22.2652 13.1054 22.5196 13.2929 22.7071C13.4804 22.8946 13.7348 23 14 23H22C22.2652 23 22.5196 22.8946 22.7071 22.7071C22.8946 22.5196 23 22.2652 23 22V14C23 13.7348 22.8946 13.4804 22.7071 13.2929C22.5196 13.1054 22.2652 13 22 13ZM21 21H15V15H21V21Z" fill="white"/>
								</svg>
							</a>
						</li>
					</ul>
				</div>



				<div className="tab-content">
					<div className="tab-pane" id="List">
						<div className="row">
							<div className="col-xl-12">
								{
									list && list.length > 0 &&  list.map((item, i) => (
										<div key={'keymatch'+i} className="d-flex flex-wrap search-row bg-white py-3 mb-3 rounded justify-content-between align-items-center pointer">
											<div className="d-flex col-xl-4 col-xxl-3 col-lg-4 col-sm-6 align-items-center">
											<img src={'/images/categorias/' + item.categoria_id + '.jpg'} className="mr-3" style={{width:'70px', height:'70px', clipPath: 'circle(45px at center)'}} alt={item.hunt} title={item.hunt}/>
												<div>
													<h2 className="title"><a className="text-black">{item.nome}</a></h2>
													<span className="text-primary category-content">{item.categoria.split(',').SortOne()} / {item.subcategoria.split(',').SortOne()}</span>
												</div>
											</div>
											<div className="d-flex col-xl-3 col-lg-4 col-sm-6 align-items-center">
												{
													parseInt(search.origin) === 0 && item.valuation && item.valuation !== "" && (
														<div>
															<span className="sub-title text-black"><b>Orçamento</b>: {Currency(item.valuation)}</span><br />
															<span><b>Mensal</b>: {Currency(item.points_max)}</span>
														</div>
													)
												}	

												{
													parseInt(search.origin) === 1 && item.points !== "" && item.points_max !== "" && (
														<div>
															<span className="sub-title text-black"><b>Valuation</b>: {Currency(item.valuation)}</span><br />
															<span><b>Avaliação</b>: {(parseFloat(item.points/item.points_max)*100).toFixed(2)}%</span>
														</div>
													)
												}	


												{
													(parseInt(search.origin) === 2 || parseInt(search.origin) === 3) && item.points !== "" &&  item.points_max !== "" && (
														<div>
															<span className="sub-title text-black"><b>Avaliação</b>: {item.valuation.toFixed(2)}%</span><br />
															<div className="progress">
																<div class="progress-bar bg-info" style={{width: (parseFloat(item.points/item.points_max)*100).toFixed(2) + '%'}}></div>
															</div>
														</div>
													)
												}

												{
													parseInt(search.origin) === 4 && item.points && item.points !== "" && item.points_max && item.points_max !== "" && (
														<div>
															<span className="sub-title text-black"><b>Capital</b>: {item.points_max === 1 ? Currency(item.valuation) : 'Não revelado'}</span><br />
															<span><b>Porcentagem</b>: {item.points.toFixed(2)}%</span>
														</div>
													)
												}			

												{
													parseInt(search.origin) === 5 && item.valor && item.valor !== "" && item.points_max && item.points_max !== "" && (
														<div>
															<span className="sub-title text-black"><b>Valor</b>: {Currency(item.valor)}</span><br />
															<span><b>Financiamento</b>: {GetFinanciamento(item.extra)}%</span>
														</div>
													)
												}
											</div>
											<div className="d-flex col-xl-3 col-lg-4 col-sm-6 mt-lg-0 mt-3 align-items-center">
												<svg className="mr-3" width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
													<rect width="54" height="54" rx="15" fill="#FBA556"></rect>
													<path d="M27 15C21.934 15 17.8125 19.1215 17.8125 24.1875C17.8125 25.8091 18.2409 27.4034 19.0515 28.7979C19.2404 29.123 19.4516 29.4398 19.6793 29.7396L26.6008 39H27.3991L34.3207 29.7397C34.5483 29.4398 34.7595 29.1231 34.9485 28.7979C35.7591 27.4034 36.1875 25.8091 36.1875 24.1875C36.1875 19.1215 32.066 15 27 15ZM27 27.2344C25.32 27.2344 23.9531 25.8675 23.9531 24.1875C23.9531 22.5075 25.32 21.1406 27 21.1406C28.68 21.1406 30.0469 22.5075 30.0469 24.1875C30.0469 25.8675 28.68 27.2344 27 27.2344Z" fill="white"></path>
												</svg>
												<div>
													<h4 className="sub-title text-black">{item.local}</h4>
													<span>{
															
															parseInt(search.origin) !== 1 && GetKind(item.risco)
														}</span>
												</div>
											</div>
									
										</div>
									))
								}



							{
								((list === undefined || list === null) || (list && list.length === 0)) && (
									<div className="col-xl-12 col-lg-6">
										<div className="items pointer">
											<div className="card card-none shadow_1">
												<div className="card-body">	
													<div className="media mb-2">
														<div className="media-body media-content">
															<div>
																<h4 className="fs-20 text-black">Nenhum Resultado encontrado</h4>
																<p className="mb-1">{(user.origin === 2 ? 'Seu cadastro está configurado para buscar profissionais' : '')}</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								)
							}

							</div>
						</div>
					</div>
					<div className="tab-pane active show" id="Boxed">					
						<div className="row">

							{
								list  && list.length > 0 &&  list.map((item, i) => (
									<div className="col-xl-4 col-lg-6" key={'key'+i}>
										<div className="items pointer" >
											<div className="card shadow_1">
												<div className="card-body">	
													<div className="media mb-2">
														<div className="media-body">
															<p className={'mb-1 ' + (parseInt(search.origin) === 5  ? 'category-content-2':'category-content')}>{item.nome}</p>
															<h4 className="fs-20 text-black category-content">{item.categoria.split(',').SortOne()}</h4>
														</div>
														{
														parseInt(search.origin) !== 4 && (<img src={'/images/categorias/' + (item.categoria_id) + '.jpg'} style={{width:'70px', height:'70px', clipPath: 'circle(48px at center)'}} alt={item.nome} title={item.nome}/>) ||
														parseInt(search.origin) === 4 && (<img src={'/images/db/categorias/' + (item.user_id) + '.jpg?' +  (Invalidator['profile_image'])} style={{width:'70px', height:'70px', clipPath: 'circle(48px at center)'}} alt={item.nome} title={item.nome}  onError={HandlerImageError}/>)
														}

													</div>
													<span className="text-primary font-w500 d-block mb-3 category-content">{item.subcategoria.split(',').SortOne()}</span>
													<p className="fs-14 desc-content">{item.descricao.trim().replace(/[\r\n]/gm, ' ')}</p>
													<div className="d-flex align-items-center mt-4">
														<a className="btn btn-primary light btn-rounded mr-auto kind">{
															parseInt(search.origin) === 5 && GetKind(item.extra) ||
															parseInt(search.origin) !== 1 && GetKind(item.risco) ||
															parseInt(search.origin) === 1 && GetKind((parseFloat(item.points/item.points_max)*100).toFixed(2))
														}</a>
														<span className="text-black font-w500 pl-3 local-content">{item.local}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								))
							}



							{
								((list === undefined || list === null) || (list && list.length === 0)) && (
									<div className="col-xl-12 col-lg-6">
										<div className="items pointer">
											<div className="card card-none shadow_1">
												<div className="card-body">	
													<div className="media mb-2">
														<div className="media-body media-content">
															<div>
																<h4 className="fs-20 text-black">Nenhum Resultado encontrado</h4>
																<p className="mb-1">{(user.origin === 2 ? 'Seu cadastro está configurado para buscar profissionais' : '')}</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								)
							}

                            
						</div>
					</div>

					<nav>
						<div className="d-flex _align-items-center flex-wrap bg-white rounded py-3 px-md-3 px-0">
							<ul className={"pagination " + (range.length===0?'hide':'')}>
								<li className="page-item page-indicator pointer">
									<a className="page-link" onClick={() => { page > 1 && LoadMatches(page-1)}}>
										<i className="la la-angle-left"></i>
									</a>
								</li>


								{
									range && range.map((item, i) => (
										<li key={'pager'+i} className={'page-item pointer ' + ((item +(page > 3 ? page-3 : 0)) === page ? 'active':'') }>
											<a className="page-link" onClick={() => { (item +(page > 3 ? page-3 : 0)) !== page && LoadMatches((item +(page > 3 ? page-3 : 0)))}}>{(item +(page > 3 ? page-3 : 0))}</a>
										</li>
									))
								}


								<li className="page-item page-indicator pointer">
									<a className="page-link" onClick={() => { page < (range[range.length-1] + (page > 3 ? page-3 : 0)) && LoadMatches(page+1)}}>
										<i className="la la-angle-right"></i>
									</a>
								</li>
							</ul>
						</div>
					</nav>
				</div>
            </div>
        </div>
    )
}