/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext'
import { InvalidatorContext } from '../../../Context/InvalidatorContext';
import { Sleep, WaitObject } from '../../../scripts/Wait';
import { Request } from '../../../scripts/Request';
import { Currency } from '../../../scripts/StringUtils';
import { HubObjects } from '../../../scripts/HubObjects';
import { FormGenerator } from '../../Form/FormGenerator';
import { useParams } from 'react-router-dom';
import ReactGA from 'react-ga';
import './index.css';


export default function Investor() {
    const component_name = "admin/investor";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Invalidator, Invalidate, InvalidateStream } = useContext(InvalidatorContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [share, setShare] = useState(false);
    const investorId = useRef(""); 
    const _formUserRef = useRef();
    const _formLoadedRef = useRef();
    const loadPrestineRef = useRef(false);
    const timerIdleRef = useRef(new Date());


    const [uinfo, setUinfo] = useState({
		nome: "",
		iam: ""
	});


    const [info, setInfo] = useState({
        investor_id: "",
        avaliacao: "",
        valor: 0,
        modalidade: 1,
        porcentagem: 10,
        oculto: 1
    });



    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        
        WaitObject(user.nome).then(() => {
            Init();
        });

        return () => {
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(loading || pageLoading){
         window.$('#preloader').fadeIn(500);
        } else {
         window.$('#preloader').fadeOut(500);
        }
     }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
    const Init = () => {
        HubObjects.Exec("HEADER_TITLE", "Perfil de Investidor");

        let _nome = "";
		let _arr_name = user.nome.split(' ');
		if(_arr_name.length > 1) {
			_nome = _arr_name[0] + " " + _arr_name[1];
		} else {
			_nome = _arr_name[0];
		}

        let _iam = "Não definido";

		if(user.origin < 3) {
			switch(user.iam) { 
				case 1:
					_iam = "Empresa"
					break;
				case 2:
					_iam = "Startup"
					break;
				case 3:
					_iam = "Instituição"
					break;
				case 4:
					_iam = "Empreendedor"
					break;
				case 9:
					_iam = "Não definido"
					break;
			}
        } else if(user.origin === 3) {
            _iam = "Profissional"
        } else if(user.origin === 4) {
            _iam = "Investidor"
        }


		setUinfo(prev => ({
			...prev,
			nome: _nome,
			iam: _iam
		}));

        setTimeout(() => {
            window.dzChartlist.load();
        }, 100);
    }
    //#endregion Init



    //#region Loaders
    const LoadCategorias = () => {
        Promise.resolve(new Request().Run("api/Auth/Categorias", "GET", "", "", 1*24*60*1000))
        .then((data) => {
            _formUserRef.current.Select("categoria", data);
            let v = _formUserRef.current.GetDataValue("categoria");
            LoadSubcategorias((v && v !== undefined && v !== null ? v : data[0].id));
        }).catch(() => {
            
        });
    }


    const LoadSubcategorias = (id, posfix = "") => {
        Promise.resolve(new Request().Run("api/Auth/Subcategorias?cat="+id, "GET", "", "", 1*24*60*1000))
        .then((data) => {
            if(data.length > 0) {
                //console.log("subcategoria"+posfix, data);
                _formUserRef.current.SelectArray("subcategoria"+posfix, data, "");
            } else {
                _formUserRef.current.SelectArray("subcategoria"+posfix, [{id: 0, name: "Geral"}], "");
            }
        }).catch(() => {
           
        });
    }


    const LoadInvestor = () => {
        if(investorId.current !== "" && investorId.current !== undefined) {
            Promise.resolve(new Request().Run("api/Investor/Info?id="+investorId.current, "GET", "", user.token, 0*1000))
            .then((data) => {
                setInfo({
                    investor_id: data.investor_id,
                    avaliacao: data.avaliacao,
                    valor: data.valor,
                    porcentagem: data.porcentagem,
                    modalidade: data.modalidade,
                    visivel: data.visivel
                });

                setTimeout(() => {
                    window.dzChartlist.load();
                }, 100);
            }).catch(() => {
            
            });
        }
    }
    //#endregion Loaders



    //#region Form Handlers
    const click_add_areas = async() => {
        let c = await _formUserRef.current.ArrayLength("areas");
        await _formUserRef.current.Push("areas", c+1);
        setTimeout(() => {
            _formUserRef.current.SetObject("remove_areas", {visible: true});
        }, 200);
    }


    const click_remove_areas = async() => {
        let c = await _formUserRef.current.ArrayLength("areas");
        await _formUserRef.current.Push("areas", c-1);
        setTimeout(() => {
            _formUserRef.current.SetObject("remove_areas", {visible: ((c-1) > 1)});
        }, 200);
    }



    const categoria = (value, posfix) => {
        //console.log(value, posfix);
        LoadSubcategorias(value, posfix);
    }


    const OnFormLoaded = async(fields) => {
        setPageLoading(true);

        WaitObject(_formUserRef.current).then(async() => {
            if(user.user_id !== "" && user.user_id !== null && user.user_id !== undefined) {
                let investor_data = await new Request().Run("api/Investor/Load?id="+user.user_id, "GET", "", user.token);
                if(investor_data !== undefined && investor_data.investor_id !== null) 
                {
                    await _formUserRef.current.Load(investor_data);
                    investorId.current = investor_data.investor_id;
                    setShare(investor_data.ativo === 1);

                    let c = await _formUserRef.current.DataLength("areas");
                    _formUserRef.current.SetObject("remove_areas", {visible: ((c) > 1)});

                }
                setPageLoading(false);
                setTimeout(() => {
                    LoadCategorias();
                    LoadInvestor();
                }, 100);
            }
        });
    }

    
    const Exec = (func, value, posfix) => {
        try {
            //console.log(func, value, posfix);
            eval(func)(value, posfix);
        } catch (e) { }
    }
    //#endregion Form Handlers


     //#region Handlers
     const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        //_formUserRef.current.Test("","");

        setPageLoading(true);

        let result = await _formUserRef.current.Post("api/Investor/Save", {investor_id: investorId.current}, user.token);
        setPageLoading(false);

        if(result === "error") {
            window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
            return;
        }
        investorId.current = result.investor_id;
        setShare(1);
        window.scrollTo({top: 0, behavior: 'smooth'});

        setTimeout(() => {
            setInfo(result);
        }, 300);
    }


    const _HandleShareClick = (e) => {
        Promise.resolve(new Request().Run("api/Investor/Share", "POST", {investor_id:investorId.current, share: e.target.checked}, user.token)).then((data) => { }).catch(() => { });
        setShare(e.target.checked);
    }


    const HandlerImageError = (e) => {
        e.target.src = "images/db/0.jpg";
	}
    //#endregion Handlers


    //#region Functions
    const GetModalidade = (index) => {
        try {
            let data =  _formUserRef.current.GetObject("modalidade");
            if(data !== null) {
                if(data.type.options.length > 0) {
                    let r = data.type.options.find(item => item.id === index);
                    if(r) {
                        return r.name;
                    }
                }
                return "";
            } else {
                return "Não preenchido";
            }
        } catch(e) {
            return "Não preenchido";
        }
    }
    //#endregion Functions




    return (
        <div className="content-body Investor">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-9 col-xxl-8 col-lg-12">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card profile-card">
                                    <div className="card-header flex-wrap border-0 pb-0">
                                        <h3 className="fs-18 text-black font-w400 mr-auto mb-2 pr-3">Preencha o máximo de campos</h3>
                                        <div className="d-sm-flex d-block">
                                            <div className="d-flex mr-5 align-items-center mb-2">
                                                <div className={'custom-control custom-switch toggle-switch text-right' + (investorId.current === "" ? ' hide': ' ')} >
                                                    <input type="checkbox" className="custom-control-input" id="share" checked={share} onClick={_HandleShareClick} />
                                                    <label className="custom-control-label" htmlFor="share">Compartilhar?</label>
                                                </div>
                                            </div>
                                            {/*
                                            <a href="#" className="btn btn-dark light btn-rounded mr-3 mb-2">Cancel</a>
                                            <a className="btn btn-primary btn-rounded mb-2" href="#">Save Changes</a>
                                            */}
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <form name="fuser" onSubmit={(event) => _HandleSubmit('reativar', event)} autoComplete="off" id="form-contact" className="" noValidate>
                                            <div className="row">
                                                <div className="col-md-12 pt-1 pb-1">
                                                    <FormGenerator ref={_formUserRef} name={'cadastro'} url={'/forms/investor.json'} execRef={Exec}></FormGenerator>
                                                    <div style={{clear:'both'}}></div>
                                                    <center>
                                                        <hr />
                                                        <button id="btn_sv" className="btn btn-primary btn-rounded mb-2" type="submit">Enviar formulario</button>
                                                    </center>
                                                    <br />
                                                </div> 
                                            </div>
                                        </form>
                                    </div>
                                </div>

                               
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-xxl-4 col-lg-12">
						<div className="row">
							<div className="col-xl-12 col-lg-6">
								<div className="card  flex-lg-column flex-md-row ">
									<div className="card-body card-body  text-center border-bottom profile-bx">
										<div className="profile-image mb-4">
											<img src={'images/db/' + user.user_id + '.jpg?' + (Invalidator['profile_image'])}  className="rounded-circle" alt=""  onError={HandlerImageError}/>
										</div>
										<h4 className="fs-22 text-black mb-1">{uinfo.nome}</h4>
										<p className="mb-4">{uinfo.iam}</p>
										{/*<div className="row">
											<div className="col-6">
												<div className="border rounded p-2">
													<h4 className="fs-22 text-black font-w600">100</h4>
													<span className="text-black">Pontos</span>
												</div>
											</div>
											<div className="col-6">
												<div className="border rounded p-2">
													<h4 className="fs-22 text-black font-w600">100</h4>
													<span className="text-black">Max Pontos</span>
												</div>
											</div>
										</div>*/}
									</div>
									<div className="card-body activity-card">
										<div className="d-flex mb-3 align-items-center">
											<a className="contact-icon mr-3" ><i className="fa fa-money" aria-hidden="true"></i></a>
											<span className="text-black">Capital disponível<br /> {Currency(info.valor)}</span>
										</div>
										<div className="d-flex mb-3 align-items-center">
											<a className="contact-icon mr-3" ><i className="fa fa-line-chart"></i></a>
											<span className="text-black">Modalidade <br /> {GetModalidade(info.modalidade)}</span>
										</div>
										{/*<div className="row text-center mt-2 mt-md-1">
											<div className="col-4 p-0">
												<div className="d-inline-block mb-2 relative donut-chart-sale">
													<span className="donut" data-peity='{ "fill": ["rgb(255, 142, 38)", "rgba(236, 236, 236, 1)"],   "innerRadius": 27, "radius": 10}'>{info.formacao}/100</span>
													<small className="text-black">{info.formacao}%</small>
												</div>
												<span className="d-block">Formação</span>
											</div>
											<div className="col-4 p-0">
												<div className="d-inline-block mb-2 relative donut-chart-sale">
													<span className="donut" data-peity='{ "fill": ["rgb(62, 168, 52)", "rgba(236, 236, 236, 1)"],   "innerRadius": 27, "radius": 10}'>{info.skill}/100</span>
													<small className="text-black">{info.skill}%</small>
												</div>
												<span className="d-block">Habilidades</span>
											</div>
											<div className="col-4 p-0">
												<div className="d-inline-block mb-2 relative donut-chart-sale">
													<span className="donut" data-peity='{ "fill": ["rgb(34, 172, 147)", "rgba(236, 236, 236, 1)"],   "innerRadius": 27, "radius": 10}'>{info.idioma}/100</span>
													<small className="text-black">{info.idioma}%</small>
												</div>
												<span className="d-block">Idiomas</span>
                                            </div>
                                        </div>*/}
									</div>
								</div>
							</div>
							<div className="col-xl-12 col-lg-6">
								<div className="card">	
									<div className="card-header border-0 pb-0">
										<h4 className="fs-20 text-black">Mais informações</h4>
									</div>
									<div className="card-body portfolios-card">
										<div className="d-flex mb-3 align-items-center rounded">
                                            <a className="contact-icon mr-3" style={{backgroundColor:'#3D6AD6', color:'#FFFFFF'}}><i className="fa fa-eye"></i></a>
											<span className="font-w500">Visibilidade<br /><small>{(info.visivel === 1 ? 'Capital oculto':'Capital visível')}</small></span>
                                        </div>
										<div className="d-flex mb-3 align-items-center rounded">
                                            <a className="contact-icon mr-3" style={{backgroundColor:'#EA518D', color:'#FFFFFF'}}><i className="fa fa-pie-chart"></i></a>
											<span className="font-w500">Avaliação<br /><small>{info.avaliacao}%</small></span>
										</div>
										<div className="d-flex mb-3 align-items-center rounded">
                                            <a className="contact-icon mr-3"  style={{backgroundColor:'#0073B1', color:'#FFFFFF'}}><i className="fa fa-bar-chart"></i></a>
											<span className="font-w500">Porcentagem<br /><small>{info && info.porcentagem && (info.porcentagem)}%</small></span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
                </div>
            </div>
        </div>
    )
}