import { AppServer, GetServer } from './Loadbalancer';
import { WaitObject, Sleep } from './Wait';


const Queue = {list: []};
const QueueTimeout = 1000;

export const Req = async(url, method = 'GET', args, authtoken = "", ttl = 0) => {
    return await new Promise(resolve => {
        resolve(new Request().Run(url, method, args, authtoken, ttl));
    });
}



export const RequestPost = async (args, url, auth) => {
    const controller = new window.AbortController();
    const signal = controller.signal;
    if(url.toLowerCase().indexOf("http") === -1 && url.substr(0,1) !== "/" && url.substr(0,2) !== "./" ) url =  AppServer(url) + url;

    const rawResponse = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            auth
        },
        signal: signal,
        body: JSON.stringify(args)
    });
    const content = await rawResponse.json();
    return content;
}


export const RequestGet = async (url) => {
    const controller = new window.AbortController();
    const signal = controller.signal;
    if(url.toLowerCase().indexOf("http") === -1 && url.substr(0,1) !== "/" && url.substr(0,2) !== "./" ) url = AppServer(url) + url;

    return await fetch(url, { method: 'GET', signal: signal })
        .then(async response => {
                try {
                    if(response.status === 401 || response.status === 403 || response.status === 405) {
                        await this.db.Clear("cache");
                        window.location.href = "/";
                    }
                } catch(e) { }
                return response.arrayBuffer()
            })
        .then(buffer => {
            let decoder = new TextDecoder("utf-8");
            let text = decoder.decode(buffer);
            return JSON.parse(text);
        });


}


export class Request {
    url = null;
    db = null;

    constructor() {
        this.state = { loading: false };
        this.Aborter();
    }

    Aborter = () => {
        this.controller = new window.AbortController();
        this.signal = this.controller.signal;
    }

    Run = async (url, method = 'GET', args, authtoken = "", ttl = 0) => {
        if(Queue.list[url]?.status > -1) {
            return await new Promise(resolve => {
                let wait_status_complete = setInterval(() => {
                    let _status =  Queue.list[url]?.status;
                    if(_status === 1) {
                        clearInterval(wait_status_complete);
                        resolve(Queue.list[url]?.data);
                    } 
                }, 100);
            });


        } else {
            Queue.list.push({url: url, status: 0, data: null}); //status 0-watting, 1-complete, 2-error
            this.Aborter();
            this.url = url;
            this.method = method;
            this.args = args || {};

            //console.log(ttl);

            //console.log(this.url);

            if(ttl > 0) {
                this.state.loading = true;

                
                try {
                    let _cache = JSON.parse(localStorage.getItem(this.url));
                    if(_cache.data === null || _cache.data === undefined || _cache.data ==="" || _cache.data?.length === 0) {
                        return this.Renew(url, method, args, authtoken, ttl);
                    } else if(new Date().getTime() - _cache.milliseconds < ttl && _cache.data !== "" && _cache.data !== null && _cache.data !== undefined && _cache.data?.length !== 0) {
                        return _cache.data;
                    } else {
                        return this.Renew(url, method, args, authtoken, ttl);
                    }
                } catch(e) {
                    return this.Renew(url, method, args, authtoken, ttl);
                }
            } else {
                //await this.db.Query("DELETE FROM cache WHERE endpoint=?", [this.url]);
                return this.Renew(url, method, args, authtoken, ttl);
            }  
        }
    }


    Renew = async(url, method = 'GET', args, authtoken = "", ttl = 0) => {
        let endpoint = url;
        if(url.toLowerCase().indexOf("http") === -1 && url.substr(0,1) !== "/" && url.substr(0,2) !== "./" ) url = AppServer(url) + url;
        //console.log(endpoint, url);

        if (this.method.toLocaleUpperCase() === "POST") {
            return await fetch(url, {
                method: this.method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': authtoken
                },
                signal: this.signal,
                body: JSON.stringify(this.args)
            }).then(async response => {
                try {
                    if(response.status === 401 || response.status === 403 || response.status === 405) {
                        window.location.href = "/";
                    }
                 } catch(e) { }
                
                this.state.loading = false;
                let r = await response.json();
                this.QueueProc(endpoint, r);
                if(ttl > 0) {
                    localStorage.setItem(endpoint, JSON.stringify({data: r, milliseconds: new Date().getTime()}));
                }

                return r;
            }).catch(async(e) => {
                
            });
        } else if (this.method.toLocaleUpperCase() === "POST_RAW") {
            return await fetch(this.url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': authtoken
                },
                signal: this.signal,
                body: JSON.stringify(this.args)
            }).then(async response => {
                try {
                    if(response.status === 401 || response.status === 403 || response.status === 405) {
                        await this.db.Clear("cache");
                        window.location.href = "/";
                    }
                    } catch(e) { }
                    return response.arrayBuffer()
                })
            .then(buffer => {
                let decoder = new TextDecoder("iso-8859-1");
                let text = decoder.decode(buffer);
                this.state.loading = false;
                this.QueueProc(endpoint, text);
                return text;
            });
        } else if (this.method.toLocaleUpperCase() === "GET_RAW") {
            return await fetch(url, {
                method: 'GET', headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': authtoken
                }, signal: this.signal
            })
            .then(async response => {
                try {
                    if(response.status === 401 || response.status === 403 || response.status === 405) {
                        await this.db.Clear("cache");
                        window.location.href = "/";
                    }
                    } catch(e) { }
                    return response.arrayBuffer()
                })
            .then(buffer => {
                let decoder = new TextDecoder("iso-8859-1");
                let text = decoder.decode(buffer);
                this.state.loading = false;
                this.QueueProc(endpoint, text);
                return text;
            });
        } else {
            return await fetch(url, {
                method: 'GET', headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': authtoken
                }, signal: this.signal
            })
            .then(async response => {
                try {
                    if(response.status === 401 || response.status === 403 || response.status === 405 || response.status === 500) {
                        //await this.db.Clear("cache");
                        window.location.href = "/";
                    }
                    } catch(e) { }
                    return response.arrayBuffer()
                })
            .then(async buffer => {
                let decoder = new TextDecoder("utf-8");
                let text = decoder.decode(buffer);
                this.state.loading = false;
                let r = JSON.parse(text);
                this.QueueProc(endpoint, r);
                try {
                    //if(r.status===401) window.location.href="/login";
                } catch(e) { }
                if(ttl > 0) {
                    localStorage.setItem(endpoint, JSON.stringify({data: r, milliseconds: new Date().getTime()}));
                }
                return r;
            }).catch(async() => {
                
            });
        }
    }


    QueueProc = (url, data) => {
        if(Queue.list[url]) {
            Queue.list[url].data = data;
            Queue.list[url].status = 1;
            //console.log(index, Queue.list[index]);
            setTimeout(async() => {
                await delete Queue.list[url];
            }, QueueTimeout);
        }
    }

    Run1 = async (url, method = 'GET', args, authtoken = "", ttl = 0) => {
        this.Aborter();
        this.url = url;
        this.method = method;
        this.args = args || {};
        if(this.url.toLowerCase().indexOf("http") === -1 && this.url.substr(0,1) !== "/" && this.url.substr(0,2) !== "./" ) this.url = AppServer(this.url) + this.url;

        if(ttl > 0) {
            this.cache = localStorage.getItem(this.url);
            try {
                let _cache = JSON.parse(this.cache);
                if(new Date().getTime() - _cache.milliseconds < ttl) {
                    //console.log("from cache",_cache.data);
                    return _cache.data;
                } else {
                    //console.log("cache renew", _cache.data);
                }
            } catch(e) {

            }
        }
        
        this.state.loading = true;
        if (this.method.toLocaleUpperCase() === "POST") {
            return await fetch(this.url, {
                method: this.method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': authtoken
                },
                signal: this.signal,
                body: JSON.stringify(this.args)
            }).then(async response => {
                this.state.loading = false;
                let r = await response.json();
                try {
                   // if(r.status===401) window.location.href="/login";
                } catch(e) { }
                if(ttl > 0) {
                    localStorage.setItem(this.url, JSON.stringify({data: r, milliseconds: new Date().getTime()}));
                }
                return r;
            }).catch((e) => {
                console.log("cache clear", e);
                localStorage.clear();
            });
        } else {
            return await fetch(this.url, {
                method: 'GET', headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': authtoken
                }, signal: this.signal
            })
            /*.then(response => response.json())
            .then(data => { 
                console.log(data);
                this.state.loading = false;
                return data;
             })*/
            .then(async response => {
                try {
                    if(response.status === 401 || response.status === 403 || response.status === 405) {
                        await this.db.Clear("cache");
                        window.location.href = "/";
                    }
                    } catch(e) { }
                    return response.arrayBuffer()
                })
            .then(buffer => {
                let decoder = new TextDecoder("utf-8");
                let text = decoder.decode(buffer);
                this.state.loading = false;
                let r = JSON.parse(text);
                try {
                    //if(r.status===401) window.location.href="/login";
                } catch(e) { }
                if(ttl > 0) localStorage.setItem(this.url, JSON.stringify({data: r, milliseconds: new Date().getTime()}));
                return r;
            }).catch(() => {
                localStorage.clear();
            });
        }
    }

    Abort = () => {
        console.log("Aborting ", this.state.loading);
        //if (this.state.loading) {
        this.controller.abort();
        this.state.loading = false;
        //}
    }

    State = () => {
        return this.state;
    }
}


export class RequestRetry {
    constructor() {
        this.state = { loading: false };
        this.Aborter();
        this.try = 5;
    }

    Aborter = () => {
        this.controller = new window.AbortController();
        this.signal = this.controller.signal;
    }

    Run = async (url, method = 'GET', args) => {
        this.Aborter();
        this.url = url;
        this.method = method;
        this.args = args || {};
        if(this.url.toLowerCase().indexOf("http") === -1 && this.url.substr(0,1) !== "/" && this.url.substr(0,2) !== "./" ) this.url = AppServer() + this.url;

        this.state.loading = true;
        if (this.method.toLocaleUpperCase() === "POST") {
            return await fetch(this.url, {
                method: this.method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                signal: this.signal,
                body: JSON.stringify(this.args)
            }).then(response => {
                this.try = 5;
                if (this.state.loading) return response.json();
                this.state.loading = false;
            }).catch(() => {
                this.try--;
                if (this.try > 0) {
                    if (this.state.loading) return this.Run(this.url, this.method, this.args);
                } else {
                    return null;
                }
            });
        } else {
            return await fetch(url, { method: 'GET', headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                    }, 
                    signal: this.signal
                })
                .then(response => response.json())
                .then(data => { 
                    console.log(data);
                    this.state.loading = false;
                    return data;
                 })
                /*.then(response => response.arrayBuffer())
                .then(buffer => {
                    let decoder = new TextDecoder("iso-8859-1");
                    let text = decoder.decode(buffer);
                    if (this.state.loading) return JSON.parse(text);
                    this.state.loading = false;
                })*/.catch(() => {
                    this.try--;
                    if (this.try > 0) {
                        if (this.state.loading) return this.Run(this.url, this.method, this.args);
                    } else {
                        return null;
                    }
                });
        }
    }

    Abort = () => {
        console.log("Aborting ", this.state.loading);
        this.state.loading = false;
        this.controller.abort();
    }

    State = () => {
        return this.state;
    }
}


/*export const RequestPost = async (args, url) => {
    const rawResponse = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(args)
    });
    const content = await rawResponse.json();
    return content;
}


export const RequestGet = async (url) => {
    return await fetch(url)
        .then(response => response.arrayBuffer())
        .then(buffer => {
            let decoder = new TextDecoder("utf-8");
            let text = decoder.decode(buffer);
            return JSON.parse(text);
        });
}*/