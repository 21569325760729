import React from 'react';



export default function Footer() {

    return (
        <div className="footer">
            <div className="copyright">
                <p>Copyright © <a>OpportunityMap</a> 2023</p>
            </div>
        </div>
    )
}