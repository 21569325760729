import React, { useEffect,  useState, useRef, Suspense, lazy, useContext } from 'react';
import { Routes, Route,  useNavigate, useLocation, useParams } from 'react-router-dom';
import { GetServer } from './scripts/Loadbalancer';
import { Context } from './Context/AuthContext';
import Login  from './components/Login/Login';
import Home from './components/Admin/Home';
import Layout from './Layout.js';
import SmartValuation from './components/Admin/SmartValuation';
import Needs from './components/Admin/Needs';
import Professional from './components/Admin/Professional';
import Headhunter from './components/Admin/Headhunter';
import Investor from './components/Admin/Investor';
import Cadastro from './components/Admin/Cadastro';
import Notificacoes from './components/Admin/Notificacoes';
import Projetos from './components/Admin/Projetos';
import Profissionais from './components/Admin/Profissionais';
import Editais from './components/Admin/Editais';
import Busca from './components/Admin/Busca';
import { Edital } from './components/Admin/Edital';


export default function RouteManager() {
    const location = useLocation();
    const isLogin = (location.pathname.toLocaleLowerCase().indexOf("login") > -1 || location.pathname === "/");
    const navigate = useNavigate();
    const loadPrestineRef = useRef(false);


    useEffect(() => {
      let wait = setTimeout(() => {
        if(!loadPrestineRef.current) {
            loadPrestineRef.current = true;
            GetServer();
        }
      }, 200);

    }, [])

    const ProtectedRoute = ({ children }) => {
        const { loading, authenticated } = useContext(Context);
        if (!authenticated && !loading && !isLogin) {
           navigate("/login");
           //return children;
        } else {
            return children;
        }

      };


    return (
    
      <Suspense fallback={<div>Carregando...</div>}>
        <Layout>
            <Routes>
                <Route exact path="/" element={<Login />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/Home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
                <Route exact path="/SmartValuation" element={<ProtectedRoute><SmartValuation /></ProtectedRoute>} />
                <Route exact path="/Needs" element={<ProtectedRoute><Needs /></ProtectedRoute>} />
                <Route exact path="/Professional" element={<ProtectedRoute><Professional /></ProtectedRoute>} />
                <Route exact path="/Headhunter" element={<ProtectedRoute><Headhunter /></ProtectedRoute>} />
                <Route exact path="/Investor" element={<ProtectedRoute><Investor /></ProtectedRoute>} />
                <Route exact path="/Cadastro" element={<ProtectedRoute><Cadastro /></ProtectedRoute>} />
                <Route exact path="/Notificacoes" element={<ProtectedRoute><Notificacoes /></ProtectedRoute>} />
                <Route exact path="/Projetos" element={<ProtectedRoute><Projetos /></ProtectedRoute>} />
                <Route exact path="/Projetos/:Id" element={<ProtectedRoute><Projetos /></ProtectedRoute>} />
                <Route exact path="/Profissionais" element={<ProtectedRoute><Profissionais /></ProtectedRoute>} />
                <Route exact path="/Profissionais/:Id" element={<ProtectedRoute><Profissionais /></ProtectedRoute>} />
                <Route exact path="/Editais" element={<ProtectedRoute><Editais /></ProtectedRoute>} />
                <Route exact path="/Editais/:Id" element={<ProtectedRoute><Edital /></ProtectedRoute>} />
                <Route exact path="/Busca" element={<ProtectedRoute><Busca /></ProtectedRoute>} />
                <Route exact path="/Busca/:Id" element={<ProtectedRoute><Busca /></ProtectedRoute>} />
            </Routes>
        </Layout>
      </Suspense>
   
  );
}