/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext'
import { Sleep, WaitObject } from '../../../scripts/Wait';
import { Request } from '../../../scripts/Request';
import { Currency } from '../../../scripts/StringUtils';
import { HubObjects } from '../../../scripts/HubObjects';
import { FormGenerator } from '../../Form/FormGenerator';
import { useParams } from 'react-router-dom';
import ReactGA from 'react-ga';
import './index.css';


export default function Needs() {
    const component_name = "admin/needs";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [share, setShare] = useState(false);
    const needId = useRef(""); 
    const _formUserRef = useRef();
    const _formLoadedRef = useRef();
    const loadPrestineRef = useRef(false);
    const timerIdleRef = useRef(new Date());


    const [uinfo, setUinfo] = useState({
		nome: "",
		iam: ""
	});


    const [info, setInfo] = useState({
        need_id: "",
        avaliacao: "",
    });


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        WaitObject(user.nome).then(() => {
            Init();
        });

        return () => {
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(loading || pageLoading){
         window.$('#preloader').fadeIn(500);
        } else {
         window.$('#preloader').fadeOut(500);
        }
     }, [loading, pageLoading]);
    //#endregion Effects


    const Init = () => {
        HubObjects.Exec("HEADER_TITLE", "Preciso de uma solução");

        let _nome = "";
		let _arr_name = user.nome.split(' ');
		if(_arr_name.length > 1) {
			_nome = _arr_name[0] + " " + _arr_name[1];
		} else {
			_nome = _arr_name[0];
		}

        let _iam = "Não definido";

		if(user.origin < 3) {
			switch(user.iam) { 
				case 1:
					_iam = "Empresa"
					break;
				case 2:
					_iam = "Startup"
					break;
				case 3:
					_iam = "Instituição"
					break;
				case 4:
					_iam = "Empreendedor"
					break;
				case 9:
					_iam = "Não definido"
					break;
			}
		}


		setUinfo(prev => ({
			...prev,
			nome: _nome,
			iam: _iam
		}));

        setTimeout(() => {
            window.dzChartlist.load();
        }, 100);
    }
    //#endregion Init


    //#region Loaders
    const LoadCategorias = () => {
        Promise.resolve(new Request().Run("api/Auth/Categorias", "GET", "", "", 1*24*60*1000))
        .then((data) => {
            _formUserRef.current.Select("categoria", data);
            //_formUserRef.current.Value("categoria", data[0].id);
            let v = _formUserRef.current.GetDataValue("categoria");
            LoadSubcategorias((v && v !== undefined && v !== null ? v : data[0].id));
        }).catch(() => {
            
        });
    }


    const LoadSubcategorias = (id) => {
        Promise.resolve(new Request().Run("api/Auth/Subcategorias?cat="+id, "GET", "", "",  1*24*60*1000))
        .then((data) => {
            if(data.length > 0) {
                _formUserRef.current.Select("subcategoria", data, "");
                //_formUserRef.current.Value("subcategoria", data[0].id);
            } else {
                _formUserRef.current.Select("subcategoria", [{id: 0, name: "Geral"}], "");
                //_formUserRef.current.Value("subcategoria", data[0].id);
            }
        }).catch(() => {
           
        });
    }


    const LoadNeeds = () => {
        if(needId.current !== "" && needId.current !== undefined) {
            Promise.resolve(new Request().Run("api/Needs/Info?id="+needId.current, "GET", "", user.token, 0*1000))
            .then((data) => {
                setInfo({
                    need_id: data.need_id,
                    avaliacao: data.avaliacao,
                    orcamento: data.orcamento,
                    mensal: data.mensal,
                    tempo: data.tempo
                });

                setTimeout(() => {
                    window.dzChartlist.load();
                }, 100);
            }).catch(() => {
            
            });
        }
    }
    //#endregion Loaders



    //#region Form Handlers
    const modelo = value => {
        WaitObject(_formUserRef.current).then(async() => {
            _formUserRef.current.SetObject("modelo_outro", {visible:(parseInt(value) === 13)});
            let _modelo = _formUserRef.current.GetObject("modelo").type.options.find(o => o.id === parseInt(value));
            _formUserRef.current.SetObject("div_model_description", {height: _modelo.height});
            document.getElementById("div_model_description").innerHTML = _modelo.description;
       });
    }


    const regime = value => {
        WaitObject(_formUserRef.current).then(async() => {
            _formUserRef.current.SetObject("regime_outro", {visible:(parseInt(value) === 9)});
       });
    }


    const categoria = value => {
        LoadSubcategorias(value);
    }


    const OnFormLoaded = async(fields) => {
       setPageLoading(true);

       WaitObject(_formUserRef.current).then(async() => {
            Preload();

            if(user.user_id !== "" && user.user_id !== null && user.user_id !== undefined) {
                let needs_data = await new Request().Run("api/Needs/Load?id="+user.user_id, "GET", "", user.token);
                if(needs_data !== undefined && needs_data.need_id !== null) {
                    await _formUserRef.current.Load(needs_data);
                    needId.current = needs_data.need_id;
                    setShare(needs_data.ativo === 1)
                }
               
                setPageLoading(false);
                setTimeout(() => {
                    LoadCategorias();
                    LoadNeeds();
                }, 100);
            }
       });
    }

    
    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


     //#region Handlers
     const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        setPageLoading(true);

        //_formUserRef.current.Test("","");
        let result = await _formUserRef.current.Post("api/Needs/Save", {need_id: needId.current}, user.token);
        setPageLoading(false);
        if(result === "error") {

            window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
            return;
        }
        needId.current = result.need_id;
        setShare(1);
        window.scrollTo({top: 0, behavior: 'smooth'});
        LoadNeeds();
    }


    const _HandleShareClick = (e) => {
        setShare(e.target.checked);
        Promise.resolve(new Request().Run("api/Needs/Share", "POST", {need_id: needId.current, share: e.target.checked}, user.token)).then((data) => { }).catch(() => { });
    }


    const HandlerImageError = (e) => {
        e.target.src = "images/db/0.jpg";
	}
    //#endregion Handlers


    //#region Functions
    const Preload = () => {
        let _modelo = _formUserRef.current.GetObject("modelo").type.options[0];
        _formUserRef.current.SetObject("div_model_description", {height: _modelo.height});
        document.getElementById("div_model_description").innerHTML = _modelo.description;
    }


    const Tempo = (m) => {
        switch(parseInt(m)) {
            case 0:
                return "Imediato";
            case 1:
                return "Até 1 mês";
            case 2:
                return "Até 2 meses";
            case 3:
                return "Até 3 meses";
            case 6:
                return "Até 6 meses";
            case 12:
                return "Até 12 meses";
            case 24:
                return "Até 24 meses";
            case 98:
                return "Mais que 24 meses";
            case 99:
                return "Indefinido";
            default:
                return "Indefinido";
        }
    }
    //#endregion Functions



    return (
        <div className="content-body Needs">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-9 col-xxl-8 col-lg-12">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card profile-card">
                                    <div className="card-header flex-wrap border-0 pb-0">
                                        <h3 className="fs-18 text-black font-w400 mr-auto mb-2 pr-3">Preencha o máximo de campos</h3>
                                        <div className="d-sm-flex d-block">
                                            <div className="d-flex mr-5 align-items-center mb-2">
                                                <div className={'custom-control custom-switch toggle-switch text-right' + (needId.current === "" ? ' hide': ' ')} >
                                                    <input type="checkbox" className="custom-control-input" id="share" checked={share} onClick={_HandleShareClick} />
                                                    <label className="custom-control-label" htmlFor="share">Compartilhar?</label>
                                                </div>
                                            </div>
                                            {/*
                                            <a href="#" className="btn btn-dark light btn-rounded mr-3 mb-2">Cancel</a>
                                            <a className="btn btn-primary btn-rounded mb-2" href="#">Save Changes</a>
                                            */}
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <form name="fuser" onSubmit={(event) => _HandleSubmit('reativar', event)} autoComplete="off" id="form-contact" className="" noValidate>
                                            <div className="row">
                                                <div className="col-md-12 pt-1 pb-1">
                                                    <FormGenerator ref={_formUserRef} name={'cadastro'} url={'/forms/needs.json'} execRef={Exec}></FormGenerator>
                                                    <div style={{clear:'both'}}></div>
                                                    <center>
                                                        <hr />
                                                        <button id="btn_sv" className="btn btn-primary btn-rounded mb-2" type="submit">Enviar formulario</button>
                                                    </center>
                                                    <br />
                                                </div> 
                                            </div>
                                        </form>
                                    </div>
                                </div>

                               
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-xxl-4 col-lg-12">
						<div className="row">
							<div className="col-xl-12 col-lg-6">
								<div className="card  flex-lg-column flex-md-row ">
									<div className="card-body card-body  text-center border-bottom profile-bx">
										<div className="profile-image mb-4">
											<img src={'images/db/' + user.user_id + '.jpg?' + (new Date().getTime())}  className="rounded-circle" alt=""  onError={HandlerImageError}/>
										</div>
										<h4 className="fs-22 text-black mb-1">{uinfo.nome}</h4>
										<p className="mb-4">{uinfo.iam}</p>
										{/*<div className="row">
											<div className="col-6">
												<div className="border rounded p-2">
													<h4 className="fs-22 text-black font-w600">100</h4>
													<span className="text-black">Pontos</span>
												</div>
											</div>
											<div className="col-6">
												<div className="border rounded p-2">
													<h4 className="fs-22 text-black font-w600">100</h4>
													<span className="text-black">Max Pontos</span>
												</div>
											</div>
										</div>*/}
									</div>
									<div className="card-body activity-card">
										<div className="d-flex mb-3 align-items-center">
											<a className="contact-icon mr-3" ><i className="fa fa-money" aria-hidden="true"></i></a>
											<span className="text-black">Orçamento aberto <br /> {info.orcamento > 0 && Currency(info.orcamento)} {info.orcamento === 0 && 'Não disponível'}</span>
										</div>
										<div className="d-flex mb-3 align-items-center">
											<a className="contact-icon mr-3" ><i className="fa fa-line-chart"></i></a>
											<span className="text-black">Projeção mensal <br /> {info.mensal > 0 && Currency(info.mensal)} { info.mensal === 0 && 'Não disponível'}</span>
										</div>
										
									</div>
								</div>
							</div>
							<div className="col-xl-12 col-lg-6">
								<div className="card">	
									<div className="card-header border-0 pb-0">
										<h4 className="fs-20 text-black">Mais informações</h4>
									</div>
									<div className="card-body portfolios-card">
										<div className="d-flex mb-3 align-items-center rounded">
                                            <a className="contact-icon mr-3" style={{backgroundColor:'#3D6AD6', color:'#FFFFFF'}}><i className="fa fa-calendar"></i></a>
											<span className="font-w500">Período de contrato<br /><small>{Tempo(info.tempo)}</small></span>
										</div>
										<div className="d-flex mb-3 align-items-center rounded">
                                            <a className="contact-icon mr-3" style={{backgroundColor:'#EA518D', color:'#FFFFFF'}}><i className="fa fa-pie-chart"></i></a>
											<span className="font-w500">Avaliação<br /><small>{info.avaliacao}%</small></span>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
                </div>
            </div>
        </div>
    )
}